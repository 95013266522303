import { VISIT_LAST_VISITS_START, VISIT_LAST_VISITS_SUCCESS, VISIT_LAST_VISITS_FAILURE } from 'redux/ActionTypes'

const INIT_STATE = {
  visits: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VISIT_LAST_VISITS_START: {
      return {
        ...state,
      }
    }
    case VISIT_LAST_VISITS_SUCCESS: {
      return {
        ...state,
        visits: action.payload.visits,
      }
    }
    case VISIT_LAST_VISITS_FAILURE: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
