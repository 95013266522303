import { all } from 'redux-saga/effects'

import accountingSagas from './Accounting'
import authSagas from './Auth'
import autocompleteFilterSagas from './AutocompleteFilter'
import campaignSagas from './Campaign'
import cartographySagas from './Cartography'
import customerSagas from './Customer'
import customerSettingsSagas from './CustomerSettings'
import errorSagas from './Error'
import profileSagas from './Profile'
import userSagas from './User'
import visitSagas from './Visit'

export default function* rootSaga() {
  yield all([
    accountingSagas(),
    authSagas(),
    autocompleteFilterSagas(),
    campaignSagas(),
    cartographySagas(),
    customerSagas(),
    customerSettingsSagas(),
    errorSagas(),
    profileSagas(),
    userSagas(),
    visitSagas(),
  ])
}
