import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import PropTypes from 'prop-types'

import { toggleCollapsedNav, switchLanguage } from 'redux/actions/Setting'
import logoWhite from 'assets/images/dmasp-logo-texte-blanc.png'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'redux/ActionTypes'

class Header extends Component {
  // state = {
  //   langSwitcher: false,
  //   appNotification: false,
  // }

  // onAppNotificationSelect = () => {
  //   this.setState({
  //     appNotification: !this.state.appNotification,
  //   })
  // }
  // onLangSwitcherSelect = () => {
  //   this.setState({
  //     langSwitcher: !this.state.langSwitcher,
  //   })
  // }
  // handleRequestClose = () => {
  //   this.setState({
  //     langSwitcher: false,
  //     appNotification: false,
  //   })
  // }

  onToggleCollapsedNav = () => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  render() {
    const { drawerType } = this.props
    let drawerStyle
    if (drawerType.includes(FIXED_DRAWER)) {
      drawerStyle = 'd-block d-xl-none'
    } else if (drawerType.includes(COLLAPSED_DRAWER)) {
      drawerStyle = 'd-block'
    } else {
      drawerStyle = 'd-none'
    }

    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>
          <IconButton className={`jr-menu-icon ${drawerStyle}`} aria-label="Menu" onClick={this.onToggleCollapsedNav}>
            <span className="menu-icon" />
          </IconButton>

          <Link className="app-logo" to="/">
            <img src={logoWhite} alt="DMASP" title="DMASP" />
          </Link>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  toggleCollapsedNav: PropTypes.func.isRequired,
  drawerType: PropTypes.string.isRequired,
}

const mapStateToProps = ({ settings }) => {
  const { navCollapsed, drawerType, locale } = settings
  return { navCollapsed, drawerType, locale }
}

export default connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header)
