import { Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { isIOS, isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'

import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'redux/ActionTypes'
import Footer from 'components/Footer'
import Header from 'components/Header/index'
import Sidebar from 'containers/SideNav/index'
import SuspenseLoader from 'components/SuspenseLoader'
import { matchPropTypes } from 'util/propTypes'

const Accounting = lazy(() => import('./routes/accounting'))
const Campaign = lazy(() => import('./routes/campaign'))
const Cartography = lazy(() => import('./routes/cartography'))
const Configuration = lazy(() => import('./routes/configuration'))
const Customer = lazy(() => import('./routes/customer'))
const Dashboard = lazy(() => import('./routes/dashboard'))
const Stripe = lazy(() => import('./routes/stripe'))
const User = lazy(() => import('./routes/user'))

const App = (props) => {
  const { match, drawerType } = props
  const drawerClass = drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer'
  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerClass

  // set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height')
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height')
  }

  return (
    <div className={`app-container ${drawerStyle}`}>
      <Sidebar />
      <div className="app-main-container">
        <div className="app-header">
          <Header />
        </div>

        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            <Suspense fallback={<SuspenseLoader />}>
              <Switch>
                <Redirect exact from={`${match.url}`} to={`${match.url}/dashboard`} />
                <Route path={`${match.url}/accounting`} component={Accounting} />
                <Route path={`${match.url}/campaign`} component={Campaign} />
                <Route path={`${match.url}/cartography`} component={Cartography} />
                <Route path={`${match.url}/customer`} component={Customer} />
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route path={`${match.url}/configuration`} component={Configuration} />
                <Route path={`${match.url}/stripe`} component={Stripe} />
                <Route path={`${match.url}/user`} component={User} />
              </Switch>
            </Suspense>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  )
}

const mapStateToProps = ({ settings }) => {
  const { drawerType } = settings
  return { drawerType }
}

App.propTypes = {
  drawerType: PropTypes.string.isRequired,
  match: matchPropTypes.isRequired,
}

export default connect(mapStateToProps)(App)
