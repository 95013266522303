import { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FaCaretDown } from 'react-icons/fa'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import IntlMessages from 'util/IntlMessages'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { authSignOut } from 'redux/actions/Auth'
import placeholder from 'assets/images/placeholder.jpg'
import { authUserPropTypes } from 'util/propTypes'

const UserInfo = ({ authUser, signOut }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const history = useHistory()

  const handleClick = (event) => {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  const handleRequestClose = () => {
    setOpen(false)
  }

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt={`${authUser.firstName} ${authUser.lastName} `}
        src={authUser.photoUrl ? authUser.photoUrl : placeholder}
        className="user-avatar"
      />
      <div className="user-detail" onClick={handleClick} role="menuitem" aria-hidden="true">
        <h4 className="user-name">
          {`${authUser.firstName} ${authUser.lastName} `}
          <FaCaretDown onClick={handleClick} />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            width: 180,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleRequestClose()
            history.push('/app/configuration/profile')
          }}
        >
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="userInfo.profile" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRequestClose()
            history.push('/app/configuration/settings')
          }}
        >
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="userInfo.settings" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRequestClose()
            signOut()
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="userInfo.logout" />
        </MenuItem>
      </Menu>
    </div>
  )
}

UserInfo.propTypes = {
  authUser: authUserPropTypes.isRequired,
  signOut: PropTypes.func.isRequired,
}

const mapStateToProps = ({ profile, settings }) => {
  const { authUser } = profile
  const { locale } = settings
  return { authUser, locale }
}

export default connect(mapStateToProps, { signOut: authSignOut })(UserInfo)
