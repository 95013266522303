import {
  CARTOGRAPHY_FETCH_MAP_VISITS_START,
  CARTOGRAPHY_FETCH_MAP_VISITS_SUCCESS,
  CARTOGRAPHY_FETCH_MAP_VISITS_FAILURE,
  CARTOGRAPHY_SET_FILTERS_START,
  CARTOGRAPHY_SET_MAP_CURRENT_POSITION_START,
  CARTOGRAPHY_SET_MAP_CURRENT_POSITION_SUCCESS,
  CARTOGRAPHY_SET_MAP_CURRENT_POSITION_ERROR,
  CARTOGRAPHY_SET_MAP_CURRENT_ZOOM_SUCCESS,
} from 'redux/ActionTypes'

const INIT_STATE = {
  currentPosition: undefined,
  currentZoom: undefined,
  filters: {},
  visits: [],
  loader: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CARTOGRAPHY_FETCH_MAP_VISITS_START: {
      return {
        ...state,
        loader: true,
      }
    }
    case CARTOGRAPHY_FETCH_MAP_VISITS_SUCCESS: {
      return {
        ...state,
        loader: false,
        visits: action.payload.visits,
      }
    }
    case CARTOGRAPHY_FETCH_MAP_VISITS_FAILURE: {
      return {
        ...state,
        loader: false,
      }
    }
    case CARTOGRAPHY_SET_FILTERS_START: {
      return {
        ...state,
        filters: action.payload,
      }
    }
    case CARTOGRAPHY_SET_MAP_CURRENT_POSITION_START: {
      return {
        ...state,
        loader: true,
      }
    }
    case CARTOGRAPHY_SET_MAP_CURRENT_POSITION_SUCCESS: {
      return {
        ...state,
        currentPosition: [action.payload.lat, action.payload.lng],
        loader: false,
      }
    }
    case CARTOGRAPHY_SET_MAP_CURRENT_POSITION_ERROR: {
      return {
        ...state,
        loader: false,
      }
    }

    case CARTOGRAPHY_SET_MAP_CURRENT_ZOOM_SUCCESS: {
      return {
        ...state,
        currentZoom: action.payload,
      }
    }
    default:
      return state
  }
}
