import {
  AUTH_EXPIRE,
  AUTH_EXPIRE_SUCCESS,
  AUTH_INIT,
  AUTH_INIT_SUCCESS,
  AUTH_INIT_URL,
  AUTH_SIGNIN,
  AUTH_SIGNIN_ERROR,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNOUT,
  AUTH_SIGNOUT_SUCCESS,
} from 'redux/ActionTypes'

export const authExpire = () => ({
  type: AUTH_EXPIRE,
})

export const authExpireSuccess = () => ({
  type: AUTH_EXPIRE_SUCCESS,
})

export const authInitStart = () => ({
  type: AUTH_INIT,
})

export const authInitUrl = (url) => ({
  type: AUTH_INIT_URL,
  payload: url,
})

export const authInitSuccess = (lastConnection) => ({
  type: AUTH_INIT_SUCCESS,
  lastConnection,
})

export const authSignInStart = (credentials) => ({
  type: AUTH_SIGNIN,
  payload: credentials,
})

export const authSignInError = () => ({
  type: AUTH_SIGNIN_ERROR,
})

export const authSignInSuccess = (payload) => ({
  type: AUTH_SIGNIN_SUCCESS,
  payload,
})

export const authSignOut = () => ({
  type: AUTH_SIGNOUT,
})

export const authSignOutSuccess = () => ({
  type: AUTH_SIGNOUT_SUCCESS,
})
