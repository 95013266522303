import CircularProgress from '@material-ui/core/CircularProgress'

import CardBox from 'components/CardBox/index'

const SuspenseLoader = () => (
  <CardBox styleName="col-lg-12" childrenStyle="d-flex justify-content-center">
    <CircularProgress size={125} />
  </CardBox>
)

export default SuspenseLoader
