/* eslint-disable no-template-curly-in-string */
const localized = {
  mixed: {
    default: 'n’est pas valide',
    required: 'est obligatoire',
    oneOf: 'doit être un des éléments suivants : ${values}',
    notOneOf: 'ne doit pas être un des éléments suivants : ${values}',
  },
  string: {
    length: 'doit faire exactement ${length} caractères',
    min: 'doit faire au minimum ${min} caractères',
    max: 'doit faire au maximum ${max} caractères',
    matches: 'doit correspondre à : "${regex}"',
    email: 'doit être un email valide',
    url: 'doit être une URL valide',
    trim: 'doit être une chaîne réduite',
    lowercase: 'doit être une chaîne en minuscule',
    uppercase: 'doit être une chaîne en majuscule',
  },
  number: {
    min: 'doit être supérieur ou égal à ${min}',
    max: 'doit être inférieur ou égal à ${max}',
    less: 'doit être inférieur à ${less}',
    more: 'doit être supérieur à ${more}',
    notEqual: 'doit être différent de ${notEqual}',
    positive: 'doit être positif',
    negative: 'doit être négatif',
    integer: 'doit être un entier',
  },
  date: {
    min: 'doit être supérieure à ${min}',
    max: 'doit être inférieure à ${max}',
  },
}

export default localized
