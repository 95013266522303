import {
  USER_DELETE_START,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_DISABLE_START,
  USER_DISABLE_SUCCESS,
  USER_DISABLE_FAILURE,
  USER_ENABLE_START,
  USER_ENABLE_SUCCESS,
  USER_ENABLE_FAILURE,
  USER_FETCH_FORM,
  USER_FETCH_FORM_SUCCESS,
  USER_FETCH_FORM_ERROR,
  USER_POST_FORM,
  USER_POST_FORM_SUCCESS,
  USER_POST_FORM_ERROR,
  USER_LIST_START,
  USER_LIST_SUCCESS,
  USER_UNVALIDATE,
  USER_UNVALIDATE_SUCCESS,
  USER_VALIDATE,
  USER_VALIDATE_SUCCESS,
} from 'redux/ActionTypes'

export const userDeleteStart = (id) => ({
  type: USER_DELETE_START,
  payload: { id },
})

export const userDeleteSuccess = (id) => ({
  type: USER_DELETE_SUCCESS,
  payload: { id },
})

export const userDeleteError = (id) => ({
  type: USER_DELETE_ERROR,
  payload: { id },
})

export const disableUserStart = (id) => ({
  type: USER_DISABLE_START,
  payload: { id },
})

export const disableUserSuccess = (user) => ({
  type: USER_DISABLE_SUCCESS,
  payload: user,
})

export const disableUserFailure = () => ({
  type: USER_DISABLE_FAILURE,
})

export const enableUserStart = (id) => ({
  type: USER_ENABLE_START,
  payload: { id },
})

export const enableUserSuccess = (user) => ({
  type: USER_ENABLE_SUCCESS,
  payload: user,
})

export const enableUserFailure = () => ({
  type: USER_ENABLE_FAILURE,
})

export const userFetchFormStart = (formName, userId) => ({
  type: USER_FETCH_FORM,
  payload: { formName, userId },
})

export const userFetchFormSuccess = (formDefinition) => ({
  type: USER_FETCH_FORM_SUCCESS,
  payload: { formDefinition },
})

export const userFetchFormError = () => ({
  type: USER_FETCH_FORM_ERROR,
})

export const userListStart = (parameters) => ({
  type: USER_LIST_START,
  payload: { parameters },
})

export const userListSuccess = (data) => ({
  type: USER_LIST_SUCCESS,
  payload: data,
})

export const userPostFormStart = (formName, userId, values, setSubmitting) => ({
  type: USER_POST_FORM,
  payload: { formName, userId, values, setSubmitting },
})

export const userPostFormSuccess = (form) => ({
  type: USER_POST_FORM_SUCCESS,
  payload: form,
})

export const userPostFormError = () => ({
  type: USER_POST_FORM_ERROR,
})

export const userUnvalidateStart = (userId) => ({
  type: USER_UNVALIDATE,
  payload: { userId },
})

export const userUnvalidateSuccess = (userData) => ({
  type: USER_UNVALIDATE_SUCCESS,
  payload: { userData },
})

export const userValidateStart = (userId) => ({
  type: USER_VALIDATE,
  payload: { userId },
})

export const userValidateSuccess = (userData) => ({
  type: USER_VALIDATE_SUCCESS,
  payload: { userData },
})
