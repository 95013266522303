import ApiError from './Error/ApiError'

export const getApiMessage = (response) => {
  if (response.status === 204) {
    return null
  }
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json()
  }
  throw new ApiError(400, 'ERROR_NOT_JSON')
}

const handleAxiosResponse = async (response) => {
  if (response.data.type === 'application/json') {
    const responseData = await response.data.text()
    const responseJson = JSON.parse(responseData)
    throw responseJson
  }

  throw response
}

const handleFetchResponse = (response) => {
  if (response.status === 403) {
    throw new ApiError(403, 'ACCESS_DENIED')
  } else if (response.status === 404) {
    throw new ApiError(404, 'ERROR_NOT_FOUND')
  } else if (response.status === 405) {
    throw new ApiError(405, 'ERROR_METHOD_NOT_ALLOWED')
  } else if (response.status === 500) {
    throw new ApiError(500, 'ERROR_INTERNAL_SERVER_ERROR')
  }

  throw response
}

export const getApiErrorMessage = async (response) => {
  try {
    // Axios
    if (response.data) {
      await handleAxiosResponse(response)
    }

    handleFetchResponse(response)
  } catch (error) {
    return error
  }
}

const ApiMessage = {
  getApiMessage,
  getApiErrorMessage,
}

export default ApiMessage
