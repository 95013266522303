import md5 from 'md5'

import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const disable = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/customers/${id}/disable`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return getApiMessage(response)
  }
  throw response
}

const enable = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/customers/${id}/enable`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return getApiMessage(response)
  }
  throw response
}

const fetchConnectLink = async (authToken) => {
  const response = await fetch(`${domainName}/api/customers/stripe/connect`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return getApiMessage(response)
  }
  throw response
}

const fetchFormAdd = async (authToken) => {
  const response = await fetch(`${domainName}/api/customers/form`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return {
      initialValues: json.initialValues,
      schema: json.schema,
    }
  }
  throw response
}

const fetchFormEdit = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/customers/form/${encodeURI(id)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return {
      initialValues: json.initialValues,
      schema: json.schema,
      customerData: json.customerData,
    }
  }
  throw response
}

const listRequest = async (authToken, parameters) => {
  const response = await fetch(`${domainName}/api/customers/?${encodeURI(parameters)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const postFormAdd = async (authToken, values) => {
  const response = await fetch(`${domainName}/api/customers/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const postFormEdit = async (authToken, id, values) => {
  const response = await fetch(`${domainName}/api/customers/${encodeURI(id)}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const uploadPhoto = async (authToken, id, file) => {
  const formData = new FormData() // eslint-disable-line no-undef
  formData.append('upload[file]', file)
  formData.append('upload[name]', file.name)
  formData.append('upload[md5]', md5(file))
  const response = await fetch(`${domainName}/api/customers/${encodeURI(id)}/photo`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      // 'Content-Type': 'multipart/form-data',
    },
    body: formData,
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const statsRequest = async (authToken, startDate, endDate) => {
  const response = await fetch(`${domainName}/api/customers/stats`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ startDate, endDate }),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

export default {
  disable,
  enable,
  fetchConnectLink,
  fetchFormAdd,
  fetchFormEdit,
  listRequest,
  postFormAdd,
  postFormEdit,
  uploadPhoto,
  statsRequest,
}
