import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { visitLastVisitsSuccess, visitLastVisitsFailure } from 'redux/actions/Visit'
import { handleErrorApi } from 'redux/actions/Error'
import { VISIT_LAST_VISITS_START } from 'redux/ActionTypes'
import { captureException } from 'util/Sentry'

import { getToken } from './Auth'
import API from '../../API'

function* fetchLastVisits() {
  try {
    const authToken = yield select(getToken)
    const response = yield call(API.visit.fetchLastVisits, authToken)
    yield put(visitLastVisitsSuccess(response?.visits))
  } catch (error) {
    captureException(error)
    yield put(handleErrorApi(error))
    yield put(visitLastVisitsFailure())
  }
}

export default function* rootSaga() {
  yield all([takeEvery(VISIT_LAST_VISITS_START, fetchLastVisits)])
}
