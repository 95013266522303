import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from 'redux/reducers/index'
import rootSaga from 'redux/sagas/index'

import history from './history'

const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line

const middlewares = [sagaMiddleware, routeMiddleware]

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)))

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../redux/reducers/index', () => {
      const nextRootReducer = require('../redux/reducers/index') // eslint-disable-line
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
