import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'redux/ActionTypes'
import { connect } from 'react-redux'
import { toggleCollapsedNav, updateWindowWidth } from 'redux/actions/Setting'
import Drawer from '@material-ui/core/Drawer'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import UserInfo from 'components/UserInfo'

import SidenavContent from './SidenavContent'

class SideNav extends PureComponent {
  componentDidMount() {
    window.addEventListener('resize', () => {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      this.props.updateWindowWidth(width)
    })
  }

  onToggleCollapsedNav = () => {
    const val = !this.props.navCollapsed
    this.props.toggleCollapsedNav(val)
  }

  render() {
    const { drawerType, navCollapsed, width } = this.props
    let drawerStyle
    if (drawerType.includes(FIXED_DRAWER)) {
      drawerStyle = 'd-xl-flex'
    } else if (drawerType.includes(COLLAPSED_DRAWER)) {
      drawerStyle = ''
    } else {
      drawerStyle = 'd-flex'
    }

    let type = 'permanent'
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary'
    }

    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav',
          }}
        >
          <UserInfo />
          <SidenavContent />
        </Drawer>
      </div>
    )
  }
}

SideNav.propTypes = {
  drawerType: PropTypes.string.isRequired,
  navCollapsed: PropTypes.bool.isRequired,
  toggleCollapsedNav: PropTypes.func.isRequired,
  updateWindowWidth: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
}

const mapStateToProps = ({ settings }) => {
  const { navCollapsed, drawerType, width } = settings
  return { navCollapsed, drawerType, width }
}

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  updateWindowWidth,
})(SideNav)
