import {
  AUTOCOMPLETE_FILTERS_CAMPAIGN_START,
  AUTOCOMPLETE_FILTERS_CAMPAIGN_SUCCESS,
  AUTOCOMPLETE_FILTERS_CAMPAIGN_ERROR,
  AUTOCOMPLETE_FILTERS_USER_START,
  AUTOCOMPLETE_FILTERS_USER_SUCCESS,
  AUTOCOMPLETE_FILTERS_USER_ERROR,
} from 'redux/ActionTypes'

export const searchCampaignStart = (text) => ({
  type: AUTOCOMPLETE_FILTERS_CAMPAIGN_START,
  payload: { text },
})

export const searchCampaignSuccess = (campaignOptions) => ({
  type: AUTOCOMPLETE_FILTERS_CAMPAIGN_SUCCESS,
  payload: { campaignOptions },
})

export const searchCampaignError = () => ({
  type: AUTOCOMPLETE_FILTERS_CAMPAIGN_ERROR,
})

export const searchUserStart = (text) => ({
  type: AUTOCOMPLETE_FILTERS_USER_START,
  payload: { text },
})

export const searchUserSuccess = (userOptions) => ({
  type: AUTOCOMPLETE_FILTERS_USER_SUCCESS,
  payload: { userOptions },
})

export const searchUserError = () => ({
  type: AUTOCOMPLETE_FILTERS_USER_ERROR,
})
