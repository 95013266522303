/* eslint-disable max-len */
import { connect } from 'react-redux'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'

import { authSignOut } from 'redux/actions/Auth'
import { profileAcceptTermOfUse } from 'redux/actions/Profile'
import TermOfUseContent from 'components/TermOfUse'
import IntlMessages from 'util/IntlMessages'

const TermOfUse = ({ acceptTermOfUse, loaderSignOut, loaderTou, signOut }) => {
  const [accepted, setAccepted] = useState(false)

  return (
    <div className="app-container">
      <div className="app-main-container">
        <div className="app-main-content-wrapper">
          <div className="app-main-content">
            <div className="app-wrapper">
              <div className="animated slideInUpTiny animation-duration-3">
                <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
                  <h2 className="title mb-3 mb-sm-0">
                    <span>
                      <IntlMessages id="tou.title" />
                    </span>
                  </h2>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TermOfUseContent />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Checkbox
                      checked={accepted}
                      onChange={(event, checked) => {
                        setAccepted(checked)
                      }}
                    />{' '}
                    <span>
                      {`J'accepte que mes données personnelles soient transmises aux partenaires de
                      l'association et ainsi recevoir des communications de leur part.`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      acceptTermOfUse()
                    }}
                    title={<IntlMessages id="tou.accept" />}
                    disabled={loaderTou || !accepted}
                  >
                    <span>
                      <IntlMessages id="tou.accept" />
                    </span>
                    {loaderTou && <i className="zmdi zmdi-spinner zmdi-hc-spin" />}
                  </Button>
                  <Button
                    onClick={() => {
                      signOut()
                    }}
                    disabled={loaderSignOut}
                    title={<IntlMessages id="tou.refuse" />}
                  >
                    <span>
                      <IntlMessages id="tou.refuse" />
                    </span>
                    {loaderSignOut && <i className="zmdi zmdi-spinner zmdi-hc-spin" />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TermOfUse.propTypes = {
  acceptTermOfUse: PropTypes.func.isRequired,
  loaderSignOut: PropTypes.bool.isRequired,
  loaderTou: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, profile }) => {
  const { loaderSignOut } = auth
  const { loaderTou } = profile
  return {
    loaderSignOut,
    loaderTou,
  }
}

export default connect(mapStateToProps, {
  acceptTermOfUse: profileAcceptTermOfUse,
  signOut: authSignOut,
})(TermOfUse)
