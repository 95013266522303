/* eslint-disable max-len */
import { H1, H2, P } from './Styled'

const TermOfUseContent = () => (
  <>
    <H2>Préambule :</H2>
    <P>
      {`DMASP est une société par action simplifiée mettant à disposition une solution permettant la
      collecte de dons, notamment au profit d'associations, de fonds de dotations, et/ou de
      fondations, par tous procédés et est composée : - d’une application mobile - d’une interface
      d’administration en ligne Les présentes Conditions Générales d’Utilisation (dites «CGU») ont
      pour objet l’encadrement juridique des modalités de mise à disposition de l’application mobile
      DMASP et de définir les conditions d’accès et d’utilisation des services par « l’utilisateur »
      et « l’administrateur ». Les CGU ont pour objet de définir les modalités et conditions dans
      lesquelles DMASP met à disposition son application mobile et les services disponibles au
      bénéfice de l’utilisateur. En utilisant l’application mobile de DMASP, l’utilisateur et
      l’administrateur acceptent sans réserve les présentes dispositions des Conditions Générales
      d’Utilisation.`}
    </P>
    <H1>Article 1 – Description de l’application.</H1>
    <P>
      {`La solution DMASP est composée de deux plateformes (interface d’administration et application
      mobile) dédiées à être utilisées par les sapeurs-pompiers dans le cadre de la gestion de leur
      amicale et notamment pour la collecte de dons. L’application concerne trois types d’individus
      : les utilisateurs, les administrateurs et les donateurs. Les utilisateurs et les
      administrateurs sont des membres de l’amicale concernée. Les donateurs peuvent être des
      personnes morales ou physiques.`}
    </P>
    <H2>1. Parcours de l’administrateur.</H2>
    <P>
      {`Pour la première connexion, DMASP fournit par mail un identifiant composé d’un trigramme et
      d’un matricule ainsi qu’un code provisoire qui lui sera par nature demandé de modifier à la
      première connexion. L’administrateur n’a accès qu’au compte lié à son amicale de
      sapeur-pompier. Il détient les coordonnées des différents comptes utilisateurs créés par
      lui-même ou par les sapeurs- pompiers de son amicale, depuis son interface disponible sur un
      site web contenant son compte administrateur. Il peut exercer différentes fonctions sur son
      compte administrateur, notamment : -La création et la modification des comptes utilisateurs.
      -La création, le suivi et la clôture des campagnes de dons. -Le visionnage des statistiques de
      l’activité des collecteurs de dons. -Le suivi de la géolocalisation des lieux de visites.
      -L’utilisation du Template de mail de remerciements pour les éventuels donateurs. -L’accès aux
      différentes informations personnelles des comptes utilisateurs. -Le contrôle et la validation
      des profils utilisateurs (notamment les informations remplies, les photos de profil.)`}
    </P>
    <H2>1.2 Parcours de l’utilisateur.</H2>
    <P>
      {`L’utilisateur télécharge l’application mobile sur les plateformes de téléchargement prévues à
      ce jour (Apple Store et Google Play). Une fois son compte créé par son administrateur, ses
      identifiants lui sont envoyés par mail et serviront d’accès à l’application mobile. Ils seront
      composés d’un trigramme et d’un matricule ainsi qu’un code provisoire qui lui sera demandé de
      modifier dès la première connexion. A la première connexion il pourra également vérifier et
      personnaliser son profil. L’utilisateur est alors basculé vers une interface regroupant les
      différentes fonctionnalités du menu d’accueil : Il peut désormais gérer la gestion des visites
      et la gestion des repasses pour les dons (absence ou présences des donateurs). Il lui est
      alors possible de déterminer les plannings des repasses. L’utilisateur peut créer une fiche
      d’informations sur les donateurs (entreprise ou personne physique ; adresse ; numéro de
      téléphone ; nom et prénom..) L’utilisateur, à travers son application, permet le versement des
      dons via carte bleue. En plus de la carte bleue, il est possible pour le donateur de verser
      son don en espèces et en chèque bancaire.`}
    </P>
    <H1>Article 2 – Conditions financières.</H1>
    <P>
      {`L’application mobile permet le versement par carte bancaire via le module de paiement et la
      market place Stripe. La société DMASP via son application n’a jamais accès aux informations
      confidentielles relatives au moyen de paiement. En effet, seule la société Stripe dispose des
      informations confidentielles (numéro de carte, date de validité et cryptogramme) qui sont
      inaccessibles aux tiers. L’application développée par DMASP permet de faciliter le paiement
      par CB mais il est tout à fait possible d’enregistrer les dons avec d’autres moyens de
      paiements classiques comme le chèque ou l’espèce.`}
    </P>
    <H1>Article 3 – Obligations et Responsabilités.</H1>
    <P>
      {`DMASP est le propriétaire de : l’intégralité de la solution quelle met à disposition de ses
      clients (Plateforme web et application mobile) DMASP reste responsable de la bonne marche : de
      l’intégralité de la solution qu’elle met à disposition de ses clients (Plateforme web et
      application mobile) La responsabilité de DMASP l’inexécution de ses obligations seraient
      imputables à la mauvaise exécution de l’application résultant d’un cas de force majeure comme
      énoncé dans la jurisprudence actuelle, du fait imprévisible d’un tiers au contrat ou encore de
      la mauvaise exécution de l’application par l’utilisateur ou l’administrateur. L’application
      n’a que pour unique finalité de servir de plateforme de collecte de dons afin d’organiser de
      manière la plus encadrée possible une campagne de dons et toute autre finalité exclue
      l’éditeur d’une quelconque responsabilité. L’utilisateur et l’administrateur sont les seuls
      responsables de la bonne utilisation avec discernement des services mis à sa disposition sur
      l’application. DMASP ne pourra être tenue responsable des conséquences directes et indirectes,
      matérielles ou immatérielles, pouvant résulter de la consultation, de l’utilisation et de
      l’interprétation des informations contenues sur l’application par l’utilisateur. L'utilisateur
      et l’administrateur déclarent connaître et accepter les caractéristiques et les limites du
      réseau Internet et, notamment les caractéristiques fonctionnelles et des performances
      techniques du réseau Internet ; les problèmes liés à connexion et/ou d'accès au réseau
      Internet et/ou aux sites web ; les problèmes liés à la disponibilité et à l'encombrement des
      réseaux ; les problèmes liés à la défaillance ou à la saturation des réseaux ; les problèmes
      liés au temps de transit, à l’accès aux informations mises en ligne, aux temps de réponse pour
      afficher, consulter, interroger ou autrement transférer des données ; les risques
      d’interruption ; l'absence de protection de certaines données contre des détournements
      éventuels ou piratage ; les risques de contamination par d’éventuels virus circulant sur les
      dits réseaux, etc. pour lesquelles la responsabilité DMASP ne saurait être engagée.
      L’utilisateur et l’administrateur s’engagent expressément : - à ne pas reproduire de façon
      permanente ou provisoire l’application, en tout ou partie, par tout moyen et sous toute forme
      ; - à ne pas utiliser de logiciels ou de procédés destinés à copier le contenu de
      l’application sans l'autorisation préalable écrite de DMASP ; - à ne pas procéder à toute
      adaptation, modification, traduction, transcription, arrangement, compilation, décompilation,
      assemblage, désassemblage, transcodage, ni appliquer la rétro- ingénierie (ou « Reverse
      Engineering ») de tout ou partie de l’application, des services et/ou du contenu ; - à ne pas
      exporter l’application, ni fusionner tout ou partie de l’application avec d'autres programmes
      informatiques ; - à ne pas procéder à des courtes citations, analyses et reproductions
      destinées à des revues de presse ainsi qu'aux autres utilisations expressément autorisées par
      la loi dans les limites et conditions fixées par cette dernière et sous réserve notamment de
      citer le nom des auteurs et la source éditoriale ; - à renoncer expressément à utiliser des
      logiciels ou dispositifs susceptibles de perturber le bon fonctionnement de l’application, ni
      à engager d'action de nature à imposer une charge disproportionnée pour les infrastructures de
      DMASP ; - à ne pas extraire ou réutiliser, y compris à des fins privées, sans autorisation
      préalable écrite de DMASP, une partie substantielle ou non du contenu des bases de données et
      archives constituées par l'application ; - à ne pas mettre en place des systèmes susceptibles
      de pirater l’application et/ou le contenu en tout ou partie, ou de nature à violer les
      présentes CGU ; - à informer DMASP dès la connaissance d'un acte de piratage et en particulier
      de toute utilisation illicite ou non contractuelle de l’application et/ou du contenu quel que
      soit le mode de diffusion ; - à ne pas vendre, louer, sous-licencier ou distribuer de quelque
      façon que ce soit l’application et/ou le contenu à des tiers.`}
    </P>
    <H1>Article 4 – Traitement des données personnelles.</H1>
    <H2>
      4.1 – Collecte et traitement des données à caractère personnel de l’utilisateur, de l’administrateur et du
      donateur
    </H2>
    <P>
      {`DMASP est responsable des traitements de données à caractère personnel mis en œuvre au titre
      de l’accès à sa solution (plateforme web et application mobile), aux contenus et à la
      fourniture de service qui découle de l’utilisation de sa solution, au sens de la loi n°78-17
      du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés modifiée et du
      Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 Avril 2016 relatif à la
      protection des personnes physiques à l’égard du traitement des données à caractère personnel
      et à la libre circulation de ces données (RGPD), entré en vigueur le 25 mai 2018. Les
      traitements des données à caractère personnel relatives à l’utilisateur ainsi mis en œuvre
      sont nécessaires à la gestion et à l’organisation des collectes de dons afin de mieux les
      sécuriser et les organiser. Les développeurs de l’application ont accès aux données
      personnelles des utilisateurs et des donateurs, dans le seul but de maintenance et de
      correction de bug concernant l’application. L’utilisateur et l’administrateur sont informés
      que leurs données à caractère personnel marquées par un astérisque sont nécessaires à la
      création de leur compte. Au stade de l’inscription d’un compte sur l’application,
      l’utilisateur et l’administrateur consentent expressément à la collecte, la commercialisation
      et au traitement de leurs données Les données collectées par l’application seront conservées
      tout au long de l’utilisation de l’application par les utilisateurs et les administrateurs.
      Les administrateurs uniquement pourront contacter DMASP pour l’effacement de leurs données
      dans les cas suivants : - en cas d’un non-renouvellement du contrat d’abonnement, - en cas de
      départ d’un utilisateur ou d’un administrateur. Les données collectées des donateurs seront
      conservées pendant 3 ans à compter du premier don et seront effacées si aucun don n’est
      effectué par la suite. DMASP s’interdit toute utilisation commerciale des données récoltées et
      s’engage à ne pas transmettre, ni vendre, ni échanger, ni donner ces dernières à un quelconque
      tiers que ce soit sans avoir reçu un consentement clair et éclairé de la part du propriétaire
      des données. DMASP est susceptible de collecter les informations suivantes : ➢ Pour
      l’association : - Le trigramme du centre de secours - Le nom du centre de secours - Le n° de
      téléphone du centre de secours - Le SDIS d’appartenance - L’adresse du centre de secours, le
      code postal et la ville du centre de secours - L’email de l’association - L’activité de
      l’association - Le n° RNA et ou le n° de SIRET de l’association - Le code APE de l’association
      - Le n° de TVA de l’association - Les dates de publication au journal officiel des
      associations reconnues d’intérêt public - L’IBAN de l’association - La civilité du Président
      de l’association - Le nom du président de l’association - Le prénom du Président de
      l’association - La date de Naissance du Président de l’association - Le n° de téléphone du
      Président de l’association - Le matricule Pompier du Président de l’association ➢ Pour
      l’administrateur et l’utilisateur : - La civilité - Lenom - Le prénom - Le n° de pièce
      d’identité - L’email - Le n° de téléphone - L’adresse, le code postal et la ville - Le
      matricule Pompier - Le grade Pompier ➢ Pour le donateur : - Les coordonnées GPS - L’identité :
      Entreprise ou particulier - La civilité - Lenom - Le prénom - L’adresse, le code postal et la
      ville - L’adresse mail - Le numéro de téléphone.`}
    </P>
    <H2>4.2 – les droits à la gestion de ses données personnelles.</H2>
    <P>
      {`L’utilisateur et l’administrateur disposent également : - Du droit de solliciter une
      opposition au traitement, - Du droit de solliciter une limitation du traitement, - Du droit à
      la portabilité de ses données, - D’un droit à l’oubli et à l’effacement numérique - Du droit
      d’introduire une réclamation auprès de la CNIL. L’utilisateur et l’administrateur peuvent
      demander la suppression de leurs données personnelles mais il sera impossible d’utiliser
      l’application. L’administrateur exerce les différents droits offerts par le règlement européen
      et notamment, le droit d’accès aux données personnelles, le droit de rectification et le droit
      à l’effacement de ses données. Conformément au RGPD, ces droits peuvent être exercés en
      contactant DMASP à l’adresse suivante : contact@dmasp.fr DMASP conserve les informations
      techniques de connexion. Le donateur, quant à lui, pourra exercer ses droits en contactant
      l’association bénéficiaire du don à l’adresse indiquée sur le reçu fiscal.`}
    </P>
    <H2>4.3 – La géolocalisation.</H2>
    <P>
      {`La position GPS est enregistrée à chaque visite. La donnée de géolocalisation n’est pas
      associée au donateur mais à l’état de la visite (absence,refus,repasse,don).`}
    </P>
    <H1>Article 5 – Droits de propriété intellectuelle.</H1>
    <P>
      {`L’éditeur, DMASP, est titulaire des droits d’auteur sur sa solution (plateforme web et
      application mobile) DMASP tant dans sa forme programmée que dans sa forme exécutée, soit : la
      charte graphique, l’icône, le logiciel permettant la navigation (comprenant code source et
      code objet, l’organigramme, les documents préparatoires et tout autre document se rapportant à
      l’application), les bases de données conçues, développées ou utilisées par DMASP. La
      dénomination DMASP, le logo DMASP, ainsi que les slogans, sont, sauf indication particulière,
      des marques déposées par DMASP. De même, les dessins, photographies, images, textes et autres
      documentations représentés sur le site internet sont objets de droits de propriété
      industrielle et/ou intellectuelle. Toute reproduction, utilisation et/ou modification qui en
      serait faite sans l’accord préalable et écrit de DMASP est interdite. À ce titre, toute
      reproduction, représentation, adaptation, traduction, et/ou transformation, partielles ou
      intégrales, ou transfert sur un autre site sont interdits.`}
    </P>
    <H1>Article 6 – Disponibilité de l’application.</H1>
    <P>
      {`DMASP s’engage à travailler dans les règles de l’art pour sécuriser l’accès, la consultation
      et l’utilisation des services de l’application. L’application est disponible 24 heures sur 24,
      7 jours sur 7 sauf en cas de force majeure ou de survenance d’un évènement hors du contrôle de
      DMASP et sous réserve d’éventuelles pannes et interventions de maintenance nécessaires au bon
      fonctionnement de l’Application. Les interventions de maintenance pourront être effectuées
      sans que les utilisateurs aient été préalablement avertis. DMASP est tenue à une obligation de
      moyen concernant l’accessibilité, le fonctionnement et/ou la disponibilité de l’application.
      DMASP se réserve le droit et la possibilité d’interrompre, de suspendre ou de limiter l’accès
      à tout ou partie de l’application, notamment en raison de contrainte technique ou juridique.`}
    </P>
    <H1>Article 7 – Durée</H1>
    <P>
      {`Les CGU demeurent en vigueur à l’égard de l’utilisateur et de l’administrateur pour toute la
      durée de la relation contractuelle avec DMASP. L'utilisateur et l’administrateur bénéficient
      du service tant que dureront les relations entre DMASP. Le terme de la relation contractuelle
      intervient notamment lors des cas suivants : - Le non-renouvellement du contrat d’abonnement.
      - La résiliation du contrat d’abonnement. - Le non-respect desdits CGU. Au terme de ces
      relations, indifféremment de la cause de cette cessation, l'utilisateur perd son droit d'accès
      et d'utilisation sur le site et le bénéfice du service. Les CGU s'appliqueront jusqu'à la
      résiliation du contrat passé avec DMASP. En cas de manquement de l'utilisateur aux CGU ou aux
      dispositions légales ou réglementaires en vigueur, DMASP se réserve le droit de résilier de
      plein droit, sans préavis ni mise en demeure, l’inscription de l'utilisateur, et ce sans
      préjudice de toute action judiciaire qui pourrait être intentée par DMASP et de tous dommages
      et intérêts qui pourraient être réclamés à l'utilisateur. Cette résiliation ne pourra en aucun
      cas donner lieu au versement d'une indemnité.`}
    </P>
    <H1>Article 8 – Evolution des CGU.</H1>
    <P>
      {`Les CGU applicables sont celles en vigueur à la date de la connexion et de l’utilisation de
      l’application par l’utilisateur. DMASP se réserve le droit de modifier, à tout moment, tout ou
      partie, des dispositions des CGU sans préavis ni information préalable des utilisateurs afin
      de les adapter aux évolutions des services, aux évolutions techniques, légales ou
      jurisprudentielles ou lors de la mise en place de nouvelles prestations. Les modifications
      éventuellement apportées par DMASP aux CGU seront portées à la connaissance des utilisateurs
      par leur simple mise en ligne. Elles sont réputées acceptées sans réserve par tout utilisateur
      et administrateur qui accède à l’application postérieurement à ladite mise en ligne.`}
    </P>
    <H1>Article 9 – Loi applicable.</H1>
    <P>
      {`Les présentes CGU sont soumises à la loi Française. Tout litige ou contestation relatif à
      l’exécution ou à l’interprétation du présent règlement qui n’aura pu être réglé à l’amiable
      entre les parties sera soumis aux tribunaux compétents du ressort de Avignon.`}
    </P>
    <H1>Article 10 – Mentions légales</H1>
    <P>
      {`L’application est éditée par DMASP. Toute question relative à l’application et aux services
      proposés doit être adressé à l’adresse suivante : contact@dmasp.fr ou par courrier postal à
      l’adresse suivante : DMASP, 46 avenue du 11 novembre 1918 - 84310, Morières-lès-Avignon.`}
    </P>
  </>
)

export default TermOfUseContent
