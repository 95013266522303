import { HIDE_MESSAGE, SHOW_MESSAGE } from 'redux/ActionTypes'

const INIT_STATE = {
  contentMessage: null,
  textMessage: '',
  typeMessage: '',
  showMessage: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_MESSAGE: {
      return {
        ...state,
        contentMessage: action.payload.contentMessage || null,
        textMessage: action.payload.textMessage,
        typeMessage: action.payload.typeMessage || 'success',
        showMessage: true,
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        contentMessage: null,
        textMessage: '',
        typeMessage: '',
        showMessage: false,
      }
    }
    default:
      return state
  }
}
