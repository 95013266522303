import { Redirect, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import App from './containers/App'
import configureStore from './store'
import history from './store/history'

export const store = configureStore()

const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Redirect exact strict from="/" to="/app/dashboard" />
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
)

export default MainApp
