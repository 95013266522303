import {
  ACCOUNTING_CALCULATE_START,
  ACCOUNTING_CALCULATE_SUCCESS,
  ACCOUNTING_CALCULATE_FAILURE,
  ACCOUNTING_EXPORT_XLS_START,
  ACCOUNTING_EXPORT_XLS_SUCCESS,
  ACCOUNTING_EXPORT_XLS_FAILURE,
  ACCOUNTING_INCOME_INIT,
} from 'redux/ActionTypes'

const INIT_STATE = {
  calculateIncomeResult: undefined,
  incomeLoading: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNTING_CALCULATE_START: {
      return {
        ...state,
        calculateIncomeResult: undefined,
        incomeLoading: true,
      }
    }
    case ACCOUNTING_CALCULATE_SUCCESS: {
      return {
        ...state,
        calculateIncomeResult: action.payload.result,
        incomeLoading: false,
      }
    }
    case ACCOUNTING_CALCULATE_FAILURE: {
      return {
        ...state,
        incomeLoading: false,
      }
    }
    case ACCOUNTING_EXPORT_XLS_START: {
      return {
        ...state,
        calculateIncomeResult: undefined,
        incomeLoading: true,
      }
    }
    case ACCOUNTING_EXPORT_XLS_SUCCESS:
    case ACCOUNTING_EXPORT_XLS_FAILURE: {
      return {
        ...state,
        incomeLoading: false,
      }
    }
    case ACCOUNTING_INCOME_INIT: {
      return {
        ...state,
        calculateIncomeResult: undefined,
        incomeLoading: false,
      }
    }
    default:
      return state
  }
}
