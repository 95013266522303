import {
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNOUT_SUCCESS,
  PROFILE_ACCEPT_TOU_START,
  PROFILE_ACCEPT_TOU_SUCCESS,
  PROFILE_ACCEPT_TOU_FAILURE,
  PROFILE_CHANGE_PASSWORD_INIT,
  PROFILE_CHANGE_PASSWORD_START,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
  PROFILE_CHANGE_PASSWORD_FAILURE,
  PROFILE_FORGOT_PASSWORD_START,
  PROFILE_FORGOT_PASSWORD_SUCCESS,
  PROFILE_FORGOT_PASSWORD_FAILTURE,
  PROFILE_REFRESH_START,
  PROFILE_REFRESH_SUCCESS,
  PROFILE_REFRESH_FAILURE,
  PROFILE_RESET_PASSWORD,
  PROFILE_RESET_PASSWORD_SUCCESS,
  PROFILE_RESET_PASSWORD_ERROR,
} from 'redux/ActionTypes'

const INIT_STATE = {
  authUser: JSON.parse(localStorage.getItem('auth_user')),
  successChangePassword: false,
  errorForgotPassword: false,
  loader: false,
  loaderTou: false,
  resetPasswordSuccess: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_SIGNIN_SUCCESS: {
      return {
        ...state,
        authUser: action.payload.user,
      }
    }
    case AUTH_SIGNOUT_SUCCESS: {
      return {
        ...state,
        authUser: null,
      }
    }
    case PROFILE_ACCEPT_TOU_START: {
      return {
        ...state,
        loaderTou: true,
      }
    }
    case PROFILE_ACCEPT_TOU_SUCCESS: {
      return {
        ...state,
        authUser: action.authUser,
        loaderTou: false,
      }
    }
    case PROFILE_ACCEPT_TOU_FAILURE: {
      return {
        ...state,
        loaderTou: false,
      }
    }
    case PROFILE_CHANGE_PASSWORD_INIT: {
      return {
        ...state,
        loader: false,
        successChangePassword: false,
      }
    }
    case PROFILE_CHANGE_PASSWORD_START: {
      return {
        ...state,
        loader: true,
        successChangePassword: false,
      }
    }
    case PROFILE_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        authUser: action.authUser,
        loader: false,
        successChangePassword: true,
      }
    }
    case PROFILE_CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        loader: false,
      }
    }
    case PROFILE_FORGOT_PASSWORD_START: {
      return {
        ...state,
        errorForgotPassword: false,
        loader: true,
      }
    }
    case PROFILE_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
      }
    }
    case PROFILE_FORGOT_PASSWORD_FAILTURE: {
      return {
        ...state,
        errorForgotPassword: true,
        loader: false,
      }
    }
    case PROFILE_REFRESH_START: {
      return {
        ...state,
        loader: true,
      }
    }
    case PROFILE_REFRESH_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload.authUser,
      }
    }
    case PROFILE_REFRESH_FAILURE: {
      return {
        ...state,
        loader: false,
      }
    }
    case PROFILE_RESET_PASSWORD: {
      return {
        ...state,
        loader: true,
      }
    }
    case PROFILE_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        resetPasswordSuccess: true,
      }
    }
    case PROFILE_RESET_PASSWORD_ERROR: {
      return {
        ...state,
        loader: false,
      }
    }
    default:
      return state
  }
}
