import axios from 'axios'

export const domainName = process.env.REACT_APP_API_DOMAIN_NAME || 'https://api.dmasp.fr'

export const axiosRequest = axios.create({
  baseURL: domainName,
})

axiosRequest.interceptors.response.use(
  (response) => response,
  async (error) => Promise.reject(error.response),
)
