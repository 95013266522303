import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

import { authInitStart, authSignInStart } from 'redux/actions/Auth'
import logoWhite from 'assets/images/dmasp-logo-texte-blanc.png'
import IntlMessages from 'util/IntlMessages'

const SignIn = ({ authInit, authSignIn, decodedToken, history, lastConnection, loader }) => {
  const [trigram, setTrigram] = useState('')
  const [staffNumber, setStaffNumber] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    authInit()
  }, [])

  useEffect(() => {
    if (decodedToken && decodedToken.exp > new Date().getTime() / 1000) {
      history.push('/')
    }
  }, [decodedToken])

  useEffect(() => {
    if (trigram === '' && lastConnection.trigram) {
      setTrigram(lastConnection.trigram)
    }
    if (staffNumber === '' && lastConnection.staffNumber) {
      setStaffNumber(lastConnection.staffNumber)
    }
  }, [lastConnection])

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated 
      slideInUpTiny animation-duration-3"
    >
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="DMASP">
            <img src={logoWhite} alt="DMASP" title="DMASP" />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="auth.login" />
            </h1>
          </div>

          <div className="app-login-form">
            <form
              onSubmit={(e) => {
                e.preventDefault()
                authSignIn({
                  trigram,
                  staffNumber,
                  password,
                })
              }}
            >
              <fieldset>
                <TextField
                  label={<IntlMessages id="auth.trigram" />}
                  fullWidth
                  onChange={(event) => {
                    setTrigram(event.target.value.toUpperCase())
                  }}
                  value={trigram}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  label={<IntlMessages id="auth.staffNumber" />}
                  fullWidth
                  onChange={(event) => {
                    setStaffNumber(event.target.value.toUpperCase())
                  }}
                  value={staffNumber}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="auth.password" />}
                  fullWidth
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                  value={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!trigram || !staffNumber || !password}
                  >
                    <IntlMessages id="auth.signIn" />
                  </Button>
                  <Link
                    to={trigram && staffNumber ? `/forgot-password/${trigram}/${staffNumber}` : '/forgot-password/'}
                    title={<IntlMessages id="auth.forgotPassword" />}
                  >
                    <IntlMessages id="auth.forgotPassword" />
                  </Link>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { decodedToken, lastConnection, loader } = auth
  return {
    decodedToken,
    lastConnection,
    loader,
  }
}

SignIn.propTypes = {
  decodedToken: PropTypes.shape({
    exp: PropTypes.number,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  lastConnection: PropTypes.shape({
    trigram: PropTypes.string,
    staffNumber: PropTypes.string,
  }),
  loader: PropTypes.bool.isRequired,
  authInit: PropTypes.func.isRequired,
  authSignIn: PropTypes.func.isRequired,
}

SignIn.defaultProps = {
  decodedToken: null,
  lastConnection: {
    trigram: '',
    staffNumber: '',
  },
}

export default connect(mapStateToProps, {
  authInit: authInitStart,
  authSignIn: authSignInStart,
})(SignIn)
