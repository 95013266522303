import { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { profileResetPasswordStart } from 'redux/actions/Profile'
import logoWhite from 'assets/images/dmasp-logo-texte-blanc.png'
import IntlMessages from 'util/IntlMessages'
import { matchPropTypes } from 'util/propTypes'

const ResetPassword = ({ loader, match, profileResetPassword, resetPasswordSuccess }) => {
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const { token } = match.params

  return (
    /* eslint-disable-next-line max-len */
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="DMASP">
            <img src={logoWhite} alt="DMASP" title="DMASP" />
          </Link>
        </div>
        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="auth.resetPassword" />
            </h1>
          </div>
          {!resetPasswordSuccess && (
            <div className="app-login-form">
              <p className="mb-0">
                <IntlMessages id="auth.resetText" />
              </p>

              <form>
                <fieldset>
                  <FormControl className="mt-1 my-sm-3" fullWidth error={passwordError} required>
                    <InputLabel htmlFor="password">
                      <IntlMessages id="auth.password" />
                    </InputLabel>
                    <Input
                      id="password"
                      label={<IntlMessages id="auth.password" />}
                      type={showPassword ? 'text' : 'password'}
                      onChange={(event) => {
                        setPassword(event.target.value)
                        setPasswordError(event.target.value.length < 1)
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => {
                              event.preventDefault()
                            }}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      margin="normal"
                    />
                  </FormControl>

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        if (!password || password.lenght < 1) {
                          if (!passwordError) {
                            setPasswordError(true)
                          }
                          return
                        }
                        profileResetPassword({
                          password,
                          token,
                        })
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="auth.send" />
                    </Button>
                    <Link to="/" title={<IntlMessages id="auth.cancel" />}>
                      <IntlMessages id="auth.cancel" />
                    </Link>
                  </div>
                </fieldset>
              </form>
            </div>
          )}
          {!loader && !!resetPasswordSuccess && (
            <div className="app-login-form">
              <IntlMessages id="auth.resetTextSuccess" />
              <div className="mt-3 d-flex align-items-right justify-content-between">
                <Link to="/" title={<IntlMessages id="auth.backLogin" />}>
                  <IntlMessages id="auth.backLogin" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ profile }) => {
  const { loader, resetPasswordSuccess } = profile
  return {
    loader,
    resetPasswordSuccess,
  }
}

ResetPassword.propTypes = {
  loader: PropTypes.bool.isRequired,
  match: matchPropTypes.isRequired,
  resetPasswordSuccess: PropTypes.bool.isRequired,
  profileResetPassword: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  profileResetPassword: profileResetPasswordStart,
})(ResetPassword)
