import { all, call, put, takeEvery } from 'redux-saga/effects'

import { authExpire } from 'redux/actions/Auth'
import { storeFormError } from 'redux/actions/Form'
import { showInfoMessage, showErrorMessage } from 'redux/actions/Message'
import { HANDLE_ERROR_API } from 'redux/ActionTypes'

import { getApiErrorMessage } from 'util/ApiMessage'
import ApiError from 'util/Error/ApiError'
import { captureMessage } from 'util/Sentry'

function* processData(data, componentId) {
  if (data && data.message) {
    const message = data.code === 422 ? 'ERROR_JSON_NOT_WELL_FORMATTED' : data.message
    // Axios
    if (data.code && data.errors) {
      const errors = Object.keys(data.errors).map((key) => {
        const error = data.errors[key]
        if (error) {
          if (Array.isArray(error)) {
            // todo
            console.warn('Error is an array')
          } else {
            return `${key} : ${error}`
          }
        }
        return null
      }, {})
      yield put(showErrorMessage(message, errors.join('\n')))

      return
    }
    // TODO voir ce qu'on fait d'un data.message qui n'est pas un forms
    // (qui n'a pas de data.errors.children)

    // Get forms error
    if (data.errors && data.errors.children) {
      const formsError = Object.keys(data.errors.children).reduce((acc, current) => {
        if (data.errors.children[current].errors) {
          acc[current] = data.errors.children[current].errors
        }
        return acc
      }, {})
      if (componentId) {
        yield put(storeFormError(componentId, formsError))
      }
      yield put(showErrorMessage('form.errorsOccured', formsError))
    } else {
      yield put(showErrorMessage(message))
    }
  }
}

function* handleErrorApiSaga({ payload }) {
  const { componentId, error } = payload
  if (error.status && error.status === 401) {
    yield put(authExpire())
    yield put(showInfoMessage('auth.expired'))
  } else {
    const response = yield call(getApiErrorMessage, error)
    if (response.code) {
      // Axios response is already parsed
      yield processData(response)
    } else if (response.json) {
      const data = yield call([response, response.json])
      yield processData(data, componentId)
    } else if (response instanceof ApiError) {
      console.error(response)
      captureMessage({ message: response.message, url: error.url })
      yield put(showErrorMessage(response.message))
    } else if (error.statusText) {
      console.error(error)
      yield put(showErrorMessage(error.statusText))
    } else {
      console.error(error)
      yield put(showErrorMessage('error.errorOccured', error))
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(HANDLE_ERROR_API, handleErrorApiSaga)])
}
