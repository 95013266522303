import {
  ACCOUNTING_CALCULATE_START,
  ACCOUNTING_CALCULATE_SUCCESS,
  ACCOUNTING_CALCULATE_FAILURE,
  ACCOUNTING_EXPORT_XLS_START,
  ACCOUNTING_EXPORT_XLS_SUCCESS,
  ACCOUNTING_EXPORT_XLS_FAILURE,
  ACCOUNTING_INCOME_INIT,
} from 'redux/ActionTypes'

export const incomeInitStart = () => ({
  type: ACCOUNTING_INCOME_INIT,
})

export const calculateIncomeStart = (campaigns) => ({
  type: ACCOUNTING_CALCULATE_START,
  payload: { campaigns },
})

export const calculateIncomeSuccess = (result) => ({
  type: ACCOUNTING_CALCULATE_SUCCESS,
  payload: { result },
})

export const calculateIncomeFailure = () => ({
  type: ACCOUNTING_CALCULATE_FAILURE,
})

export const exportXlsIncomeStart = (campaigns) => ({
  type: ACCOUNTING_EXPORT_XLS_START,
  payload: { campaigns },
})

export const exportXlsIncomeSuccess = () => ({
  type: ACCOUNTING_EXPORT_XLS_SUCCESS,
})

export const exportXlsIncomeFailure = () => ({
  type: ACCOUNTING_EXPORT_XLS_FAILURE,
})
