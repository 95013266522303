import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const update = async (authToken, values) => {
  const response = await fetch(`${domainName}/api/customer-settings/update`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

export default {
  update,
}
