import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { Redirect, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import { authInitUrl } from 'redux/actions/Auth'
import NoMatch from 'components/NoMatch'
import Notification from 'components/Notification'
import StagingAlert from 'components/StagingAlert'
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from 'constants/ThemeColors'
import MuiPickersWrapper from 'containers/MuiPickersWrapper'
import { gaPageView } from 'util/analytics'

import amberTheme from './themes/amberTheme'
import AppLocale from '../languageProvider'
import blueTheme from './themes/blueTheme'
import cyanTheme from './themes/cyanTheme'
import greenTheme from './themes/greenTheme'
import indigoTheme from './themes/indigoTheme'
import orangeTheme from './themes/orangeTheme'
import pinkTheme from './themes/pinkTheme'
import purpleTheme from './themes/purpleTheme'

import SignIn from './SignIn'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import TermOfUse from './TermOfUse'
import ChangeFirstPassword from './ChangeFirstPassword'

import MainApp from '../app/index'
import '../styles/app.scss'
import '../styles/bootstrap.scss'

const RestrictedRoute = ({ component: Component, ...rest }) => (
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  <Route {...rest} render={(props) => <Component {...props} />} />
)

RestrictedRoute.propTypes = {
  decodedToken: PropTypes.shape({}),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  location: PropTypes.shape({}),
}

RestrictedRoute.defaultProps = {
  decodedToken: null,
  location: null,
}

const getColorTheme = (themeColor) => {
  switch (themeColor) {
    case CYAN: {
      return createTheme(cyanTheme)
    }
    case AMBER: {
      return createTheme(amberTheme)
    }
    case DEEP_ORANGE: {
      return createTheme(orangeTheme)
    }
    case PINK: {
      return createTheme(pinkTheme)
    }
    case BLUE: {
      return createTheme(blueTheme)
    }
    case DEEP_PURPLE: {
      return createTheme(purpleTheme)
    }
    case GREEN: {
      return createTheme(greenTheme)
    }
    case DARK_INDIGO: {
      return createTheme(indigoTheme)
    }
    case DARK_CYAN: {
      return createTheme(cyanTheme)
    }
    case DARK_AMBER: {
      return createTheme(amberTheme)
    }
    case DARK_DEEP_ORANGE: {
      return createTheme(orangeTheme)
    }
    case DARK_PINK: {
      return createTheme(pinkTheme)
    }
    case DARK_BLUE: {
      return createTheme(blueTheme)
    }
    case DARK_DEEP_PURPLE: {
      return createTheme(purpleTheme)
    }
    case DARK_GREEN: {
      return createTheme(greenTheme)
    }
    case INDIGO:
    default: {
      return createTheme(indigoTheme)
    }
  }
}

let previousPathname = ''

const App = ({ authUser, decodedToken, locale, location, themeColor, history }) => {
  history.listen((currentLocation) => {
    if (currentLocation && currentLocation.pathname) {
      if (currentLocation.pathname !== previousPathname) {
        previousPathname = currentLocation.pathname
        gaPageView(currentLocation.pathname)
      }
    }
  })

  const applyTheme = getColorTheme(themeColor)

  if (
    !history.location.pathname.includes('/') &&
    !history.location.pathname.includes('/signin') &&
    !history.location.pathname.includes('/forgot-password') &&
    !history.location.pathname.includes('/reset-password') &&
    !history.location.pathname.includes('/change-first-password')
  ) {
    authInitUrl(history.location.pathname)
  }

  if (
    !(
      location.pathname.startsWith('/forgot-password') ||
      location.pathname.startsWith('/reset-password') ||
      location.pathname.startsWith('/signin')
    )
  ) {
    if (!authUser || !decodedToken) {
      if (location.pathname !== '/signin' && !location.pathname.startsWith('/forgot-password')) {
        return <Redirect to="/signin" />
      }
    } else if (decodedToken && decodedToken.exp < new Date().getTime() / 1000) {
      if (location.pathname !== '/signin') {
        return <Redirect to="/signin" />
      }
    } else if (!authUser.termOfUseAccepted) {
      if (location.pathname !== '/tou') {
        return <Redirect to="/tou" />
      }
    } else if (!authUser.passwordModified) {
      if (location.pathname !== '/change-first-password') {
        return <Redirect to="/change-first-password" />
      }
    }
  }

  const currentAppLocale = AppLocale[locale.locale]

  return (
    <ThemeProvider theme={applyTheme}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <MuiPickersWrapper>
          <StagingAlert />
          <div className="app-main">
            <Switch>
              <RestrictedRoute path="/app" component={MainApp} />
              <Route path="/signin" component={SignIn} />
              <Route path="/forgot-password/:trigram/:staffNumber" component={ForgotPassword} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password/:token" component={ResetPassword} />
              <Route path="/tou" component={TermOfUse} />
              <Route path="/change-first-password" component={ChangeFirstPassword} />
              <Route component={NoMatch} />
            </Switch>
            <Notification />
          </div>
        </MuiPickersWrapper>
      </IntlProvider>
    </ThemeProvider>
  )
}

const mapStateToProps = ({ auth, profile, settings }) => {
  const { locale, themeColor, sideNavColor } = settings
  const { decodedToken } = auth
  const { authUser } = profile
  return {
    authUser,
    decodedToken,
    locale,
    sideNavColor,
    themeColor,
  }
}

App.propTypes = {
  authUser: PropTypes.shape({
    termOfUseAccepted: PropTypes.bool,
    passwordModified: PropTypes.bool,
  }),
  decodedToken: PropTypes.shape({
    exp: PropTypes.number,
  }),
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
  locale: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
}

App.defaultProps = {
  authUser: null,
  decodedToken: null,
}

export default connect(mapStateToProps, { authInitUrl })(App)
