import {
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_SUCCESS,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_ERROR,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_SUCCESS,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_ERROR,
} from 'redux/ActionTypes'

export const updateDefaultPositionStart = (position) => ({
  type: CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START,
  payload: position,
})

export const updateDefaultPositionSuccess = (data) => ({
  type: CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_SUCCESS,
  payload: data,
})

export const updateDefaultPositionError = () => ({
  type: CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_ERROR,
})

export const updateDefaultZoomStart = (zoom) => ({
  type: CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START,
  payload: zoom,
})

export const updateDefaultZoomSuccess = (data) => ({
  type: CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_SUCCESS,
  payload: data,
})

export const updateDefaultZoomError = () => ({
  type: CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_ERROR,
})
