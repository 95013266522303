import {
  CUSTOMER_DISABLE_START,
  CUSTOMER_DISABLE_SUCCESS,
  CUSTOMER_DISABLE_FAILURE,
  CUSTOMER_ENABLE_START,
  CUSTOMER_ENABLE_SUCCESS,
  CUSTOMER_ENABLE_FAILURE,
  CUSTOMER_FETCH_CONNECT_LINK_START,
  CUSTOMER_FETCH_CONNECT_LINK_END,
  CUSTOMER_FETCH_FORM_START,
  CUSTOMER_FETCH_FORM_SUCCESS,
  CUSTOMER_STATS_SUCCESS,
  CUSTOMER_LIST_START,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_UPLOAD_PHOTO_START,
  CUSTOMER_UPLOAD_PHOTO_SUCCESS,
  CUSTOMER_UPLOAD_PHOTO_FAILURE,
} from 'redux/ActionTypes'

const INIT_STATE = {
  loader: false,
  initialValues: null,
  rawDataTablesResponse: {
    data: [],
    draw: 0,
    error: '',
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  schema: null,
  statsResponse: [],
  customerData: null,
  customerPendingActions: [],
  uploadPhotoLoader: false,
  fetchedConnectLink: null,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_DISABLE_START:
    case CUSTOMER_ENABLE_START:
      return {
        ...state,
        customerPendingActions: [...state.customerPendingActions, { id: action.payload.id, status: 'PENDING' }],
      }
    case CUSTOMER_DISABLE_SUCCESS:
    case CUSTOMER_ENABLE_SUCCESS:
      return {
        ...state,
        customerPendingActions: state.customerPendingActions.filter((p) => p.id !== action.payload.id),
        rawDataTablesResponse: {
          ...state.rawDataTablesResponse,
          data: state.rawDataTablesResponse.data.map((d) => {
            if (d['c.id'] === action.payload.id) {
              const newd = d
              newd['c.enabled'] = action.payload.enabled
              return newd
            }
            return d
          }),
        },
      }
    case CUSTOMER_DISABLE_FAILURE:
    case CUSTOMER_ENABLE_FAILURE:
      return {
        ...state,
        customerPendingActions: state.customerPendingActions.filter((p) => p.id !== action.payload.id),
      }
    case CUSTOMER_FETCH_CONNECT_LINK_START: {
      return {
        ...state,
        loader: true,
        fetchedConnectLink: null,
      }
    }
    case CUSTOMER_FETCH_CONNECT_LINK_END: {
      return {
        ...state,
        loader: false,
        fetchedConnectLink: action.payload.fetchedConnectLink,
      }
    }
    case CUSTOMER_FETCH_FORM_START: {
      return {
        ...state,
        loader: true,
        initialValues: null,
        schema: null,
        customerData: null,
      }
    }
    case CUSTOMER_FETCH_FORM_SUCCESS: {
      return {
        ...state,
        loader: false,
        initialValues: action.payload.formDefinition.initialValues,
        schema: action.payload.formDefinition.schema,
        customerData: action.payload.formDefinition.customerData,
      }
    }
    case CUSTOMER_LIST_START: {
      return {
        ...state,
      }
    }
    case CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        rawDataTablesResponse: action.payload,
      }
    }
    case CUSTOMER_STATS_SUCCESS: {
      return {
        ...state,
        statsResponse: action.payload,
      }
    }
    case CUSTOMER_UPLOAD_PHOTO_START: {
      return {
        ...state,
        uploadPhotoLoader: true,
      }
    }
    case CUSTOMER_UPLOAD_PHOTO_SUCCESS: {
      return {
        ...state,
        uploadPhotoLoader: false,
        customerData: action.payload.customerData,
      }
    }
    case CUSTOMER_UPLOAD_PHOTO_FAILURE: {
      return {
        ...state,
        uploadPhotoLoader: false,
      }
    }
    default:
      return state
  }
}
