import styled from 'styled-components'

export const H1 = styled.h1`
  color: #212529;
  font-size: 16px;
`

export const H2 = styled.h2`
  color: #212529;
  font-size: 14px;
  text-decoration-line: underline;
  text-decoration-color: #212529;
`

export const P = styled.p`
  margin-bottom: 15px;
  padding: 5px;
  color: #212529;
  text-align: justify;
`
