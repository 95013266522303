import ReactGA from 'react-ga'

const initReactGA = () => {
  // if (process.env.RELEASE !== 'dev') {
  ReactGA.initialize(process.env.GA_TRACKING_CODE)
  // }
}

const gaPageView = (pathname) => {
  ReactGA.pageview(pathname)
}

export { initReactGA, gaPageView }
