import { FORM_RESET_ERROR, FORM_STORE_ERROR } from 'redux/ActionTypes'

export const resetFormError = (componentId) => ({
  type: FORM_RESET_ERROR,
  payload: { componentId },
})

export const storeFormError = (componentId, errors) => ({
  type: FORM_STORE_ERROR,
  payload: { componentId, errors },
})
