import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import {
  searchCampaignSuccess,
  searchCampaignError,
  searchUserSuccess,
  searchUserError,
} from 'redux/actions/AutocompleteFilter'
import { handleErrorApi } from 'redux/actions/Error'
import { AUTOCOMPLETE_FILTERS_CAMPAIGN_START, AUTOCOMPLETE_FILTERS_USER_START } from 'redux/ActionTypes'
import API from '../../API'
import { getToken } from './Auth'

function* filtersCampaignSaga({ payload }) {
  try {
    const { text } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.campaign.search, authToken, text || '')
    yield put(searchCampaignSuccess(response))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(searchCampaignError())
  }
}

function* filtersUserSaga({ payload }) {
  try {
    const { text } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.user.search, authToken, text || '')
    yield put(searchUserSuccess(response))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(searchUserError())
  }
}

export function* filterCampaignStart() {
  yield takeEvery(AUTOCOMPLETE_FILTERS_CAMPAIGN_START, filtersCampaignSaga)
}

export function* filterUserStart() {
  yield takeEvery(AUTOCOMPLETE_FILTERS_USER_START, filtersUserSaga)
}

export default function* rootSaga() {
  yield all([fork(filterCampaignStart), fork(filterUserStart)])
}
