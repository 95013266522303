import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import IntlMessages from 'util/IntlMessages'

const Item = ({ item }) => {
  const { id, link, icon } = item

  return (
    <li>
      <NavLink className="prepend-icon" activeClassName="active" to={link}>
        <i className={`zmdi zmdi-hc-fw ${icon}`} />
        <span className="nav-text">
          <IntlMessages id={id} />
        </span>
      </NavLink>
    </li>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
}

export default Item
