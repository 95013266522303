import {
  AUTOCOMPLETE_FILTERS_CAMPAIGN_START,
  AUTOCOMPLETE_FILTERS_CAMPAIGN_SUCCESS,
  AUTOCOMPLETE_FILTERS_CAMPAIGN_ERROR,
  AUTOCOMPLETE_FILTERS_USER_START,
  AUTOCOMPLETE_FILTERS_USER_SUCCESS,
  AUTOCOMPLETE_FILTERS_USER_ERROR,
} from 'redux/ActionTypes'

const INIT_STATE = {
  campaignLoading: false,
  campaignOptions: [],
  userLoading: false,
  userOptions: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTOCOMPLETE_FILTERS_CAMPAIGN_START: {
      return {
        ...state,
        campaignLoading: true,
      }
    }
    case AUTOCOMPLETE_FILTERS_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        campaignLoading: false,
        campaignOptions: action.payload.campaignOptions,
      }
    }
    case AUTOCOMPLETE_FILTERS_CAMPAIGN_ERROR: {
      return {
        ...state,
        campaignLoading: false,
      }
    }
    case AUTOCOMPLETE_FILTERS_USER_START: {
      return {
        ...state,
        userLoading: true,
      }
    }
    case AUTOCOMPLETE_FILTERS_USER_SUCCESS: {
      return {
        ...state,
        userLoading: false,
        userOptions: action.payload.userOptions,
      }
    }
    case AUTOCOMPLETE_FILTERS_USER_ERROR: {
      return {
        ...state,
        userLoading: false,
      }
    }
    default:
      return state
  }
}
