import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { handleErrorApi } from 'redux/actions/Error'
import {
  updateDefaultPositionSuccess,
  updateDefaultPositionError,
  updateDefaultZoomSuccess,
  updateDefaultZoomError,
} from 'redux/actions/CustomerSettings'
import { profileRefreshSuccess } from 'redux/actions/Profile'
import {
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START,
} from 'redux/ActionTypes'

import { storeUser } from './Profile'

import API from '../../API'
import { getToken } from './Auth'

function* updateDefaultPosition({ payload }) {
  try {
    const { defaultPosition } = payload
    const authToken = yield select(getToken)
    const authUser = yield call(API.customerSettings.update, authToken, {
      defaultPosition,
    })
    yield call(storeUser, authUser)
    yield put(updateDefaultPositionSuccess(authUser))
    yield put(profileRefreshSuccess(authUser))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(updateDefaultPositionError())
  }
}

function* updateDefaultZoom({ payload }) {
  try {
    const { defaultZoom } = payload
    const authToken = yield select(getToken)
    const authUser = yield call(API.customerSettings.update, authToken, {
      defaultZoom,
    })
    yield call(storeUser, authUser)
    yield put(updateDefaultZoomSuccess(authUser))
    yield put(profileRefreshSuccess(authUser))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(updateDefaultZoomError())
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START, updateDefaultPosition),
    takeEvery(CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START, updateDefaultZoom),
  ])
}
