import {
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_SUCCESS,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_ERROR,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_SUCCESS,
  CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_ERROR,
} from 'redux/ActionTypes'

const INIT_STATE = {
  updatingDefaultPosition: false,
  updatingDefaultZoom: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START: {
      return {
        ...state,
        updatingDefaultPosition: true,
      }
    }
    case CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_SUCCESS: {
      return {
        ...state,
        updatingDefaultPosition: false,
      }
    }
    case CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_ERROR: {
      return {
        ...state,
        updatingDefaultPosition: false,
      }
    }
    case CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START: {
      return {
        ...state,
        updatingDefaultZoom: true,
      }
    }
    case CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_SUCCESS: {
      return {
        ...state,
        updatingDefaultZoom: false,
      }
    }
    case CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_ERROR: {
      return {
        ...state,
        updatingDefaultZoom: false,
      }
    }
    default:
      return state
  }
}
