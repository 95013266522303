import axios from 'axios'

import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const addAssign = async (authToken, campaignId, userId, data) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(campaignId)}/assignments/assign`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, data }),
  })
  if (response.ok) {
    return true
  }
  throw response
}

const addCollect = async (authToken, campaignId, userId, data) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(campaignId)}/assignments/collect`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, data }),
  })
  if (response.ok) {
    return true
  }
  throw response
}

const addLoss = async (authToken, campaignId, userId, data) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(campaignId)}/assignments/loss`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, data }),
  })
  if (response.ok) {
    return true
  }
  throw response
}

const close = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(id)}/close`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const deleteCampaign = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/campaigns/delete`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
  })
  if (response.ok) {
    return id
  }
  throw response
}

const fetchFormAdd = async (authToken) => {
  const response = await fetch(`${domainName}/api/campaigns/form`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return {
      initialValues: json.initialValues,
      schema: json.schema,
    }
  }
  throw response
}

const fetchFormData = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(id)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const fetchFormEdit = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/campaigns/form/${encodeURI(id)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return {
      initialValues: json.initialValues,
      schema: json.schema,
      campaignData: json.campaignData,
    }
  }
  throw response
}

const fetchStats = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(id)}/stats`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const isClosable = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(id)}/closable`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json.isClosable
  }
  throw response
}

const exportVisits = async (authToken, id) => {
  const response = await axios.get(`${domainName}/api/campaigns/${encodeURI(id)}/export`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    responseType: 'blob',
  })
  if (response.status === 200) {
    return response.data
  }
  throw response
}

const listAssignmentRequest = async (authToken, id, parameters) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(id)}/assignments/?${encodeURI(parameters)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const listAssignmentActionRequest = async (authToken, id, parameters) => {
  const response = await fetch(
    `${domainName}/api/campaigns/${encodeURI(id)}/assignment-actions/?${encodeURI(parameters)}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const listRequest = async (authToken, parameters) => {
  const response = await fetch(`${domainName}/api/campaigns/?${encodeURI(parameters)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const listVisitRequest = async (authToken, id, parameters) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(id)}/visits/?${encodeURI(parameters)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const postFormAdd = async (authToken, values) => {
  const response = await fetch(`${domainName}/api/campaigns/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const postFormEdit = async (authToken, id, values) => {
  const response = await fetch(`${domainName}/api/campaigns/${encodeURI(id)}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const search = async (authToken, text) => {
  const response = await fetch(`${domainName}/api/campaigns/search/${encodeURI(text)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

export default {
  addAssign,
  addCollect,
  addLoss,
  close,
  deleteCampaign,
  exportVisits,
  fetchFormAdd,
  fetchFormData,
  fetchFormEdit,
  fetchStats,
  isClosable,
  listAssignmentRequest,
  listAssignmentActionRequest,
  listRequest,
  listVisitRequest,
  postFormAdd,
  postFormEdit,
  search,
}
