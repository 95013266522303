import { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import Alert from '@material-ui/lab/Alert'

const getText = () => {
  switch (process.env.RELEASE) {
    case 'developement':
      return 'Serveur de dévelopement'
    case 'staging':
      return 'Serveur de recette'
    default:
      return ''
  }
}

const StagingAlert = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (process.env.RELEASE === 'staging' || process.env.RELEASE === 'dev') {
        setOpen(true)
      }
    }, 1200)
  }, [])

  return (
    <Snackbar
      autoHideDuration={3000}
      TransitionComponent={(props) => (
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        <Slide {...props} direction="down" />
      )}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key="StagingAlert"
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <Alert severity="warning" variant="filled">
        {getText()}
      </Alert>
    </Snackbar>
  )
}

export default StagingAlert
