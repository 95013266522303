const Footer = () => {
  const currentDate = new Date()
  return (
    <footer className="app-footer">
      <div className="d-flex flex-row justify-content-between">
        <div>
          <span> Copyright DMASP &copy; 2018-{currentDate.getFullYear()}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
