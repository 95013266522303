import { HIDE_MESSAGE, SHOW_MESSAGE } from 'redux/ActionTypes'

export const showMessage = (message, content) => ({
  type: SHOW_MESSAGE,
  payload: {
    textMessage: message,
    typeMessage: 'success',
    contentMessage: content,
  },
})

export const showInfoMessage = (message, content) => ({
  type: SHOW_MESSAGE,
  payload: {
    textMessage: message,
    typeMessage: 'info',
    contentMessage: content,
  },
})

export const showErrorMessage = (message, content) => ({
  type: SHOW_MESSAGE,
  payload: {
    textMessage: message,
    typeMessage: 'error',
    contentMessage: content,
  },
})

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
})
