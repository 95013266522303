import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import { authSignOut } from 'redux/actions/Auth'
import { profileChangePasswordInit, profileChangePasswordStart } from 'redux/actions/Profile'
import logoWhite from 'assets/images/dmasp-logo-texte-blanc.png'
import IntlMessages from 'util/IntlMessages'

const ChangeFirstPassword = ({
  loader,
  loaderSignOut,
  signOut,
  changePasswordInit,
  changePasswordStart,
  successChangePassword,
}) => {
  useEffect(() => {
    changePasswordInit()
  }, [])

  const [password, setPassword] = useState('')

  return (
    /* eslint-disable-next-line max-len */
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="DMASP">
            <img src={logoWhite} alt="DMASP" title="DMASP" />
          </Link>
        </div>
        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="auth.changePassword" />
            </h1>
          </div>
          {!loader && !successChangePassword && (
            <div className="app-login-form">
              <p className="mb-0">
                <IntlMessages id="auth.changeText" />
              </p>

              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="auth.password" />}
                    type="password"
                    fullWidth
                    onChange={(event) => setPassword(event.target.value)}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        changePasswordStart(password)
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="auth.send" />
                    </Button>
                    <Button
                      onClick={() => {
                        signOut()
                      }}
                      disabled={loaderSignOut}
                      title={<IntlMessages id="auth.logout" />}
                    >
                      <span>
                        <IntlMessages id="auth.logout" />
                      </span>
                      {loaderSignOut && <i className="zmdi zmdi-spinner zmdi-hc-spin" />}
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          )}
          {!loader && successChangePassword && (
            <div className="app-login-form">
              <IntlMessages id="auth.changeTextSuccess" />
              <div className="mt-3 d-flex align-items-right justify-content-between">
                <Link to="/" title={<IntlMessages id="auth.goToApp" />}>
                  <IntlMessages id="auth.goToApp" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ auth, profile }) => {
  const { loader, successChangePassword } = profile
  const { loaderSignOut } = auth
  return {
    loader,
    loaderSignOut,
    successChangePassword,
  }
}

ChangeFirstPassword.propTypes = {
  loader: PropTypes.bool.isRequired,
  loaderSignOut: PropTypes.bool.isRequired,
  changePasswordInit: PropTypes.func.isRequired,
  changePasswordStart: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  successChangePassword: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, {
  signOut: authSignOut,
  changePasswordInit: profileChangePasswordInit,
  changePasswordStart: profileChangePasswordStart,
})(ChangeFirstPassword)
