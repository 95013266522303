import { VISIT_LAST_VISITS_FAILURE, VISIT_LAST_VISITS_START, VISIT_LAST_VISITS_SUCCESS } from 'redux/ActionTypes'

export const visitLastVisitsStart = () => ({
  type: VISIT_LAST_VISITS_START,
})

export const visitLastVisitsSuccess = (visits) => ({
  type: VISIT_LAST_VISITS_SUCCESS,
  payload: { visits },
})

export const visitLastVisitsFailure = () => ({
  type: VISIT_LAST_VISITS_FAILURE,
})
