import { useEffect } from 'react'
import { connect } from 'react-redux'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'

import { hideMessage } from 'redux/actions/Message'
import ApiError from 'util/Error/ApiError'
import IntlMessages from 'util/IntlMessages'
import { captureException } from 'util/Sentry'

const timeout = 5000

const provideMessageContent = (contentMessage) => {
  if (!contentMessage) return null

  if (typeof contentMessage === 'string') {
    return contentMessage
  }

  if (typeof contentMessage === 'object') {
    return ''
  }

  if (contentMessage instanceof Error) {
    return contentMessage.message
  }

  if (contentMessage instanceof ApiError) {
    return contentMessage.message
  }

  const error = new Error(`Unhandled case : ${typeof contentMessage}`, JSON.stringify(contentMessage))
  captureException(error)

  return ''
}

const Notification = (props) => {
  useEffect(() => {
    if (props.showMessage) {
      setTimeout(() => {
        props.hideMessage()
      }, 100)
    }
  })

  useEffect(() => {
    const { contentMessage, showMessage, textMessage, typeMessage } = props

    const content = provideMessageContent(contentMessage)

    const message = textMessage.includes(' ') ? textMessage : <IntlMessages id={textMessage} />

    if (showMessage && typeMessage === 'error') {
      if (content) {
        NotificationManager.error(content, message, timeout)
      } else {
        NotificationManager.error('', message, timeout)
      }
    }
    if (showMessage && typeMessage === 'success') {
      if (content) {
        NotificationManager.success(content, message, timeout)
      } else {
        NotificationManager.success('', message)
      }
    }
    if (showMessage && typeMessage === 'info') {
      if (content) {
        NotificationManager.info(content, message, timeout)
      } else {
        NotificationManager.info('', message, timeout)
      }
    }
  })

  return (
    <div>
      <NotificationContainer />
    </div>
  )
}

const mapStateToProps = ({ message }) => {
  const { contentMessage, showMessage, textMessage, typeMessage } = message
  return {
    contentMessage,
    showMessage,
    textMessage,
    typeMessage,
  }
}

Notification.propTypes = {
  contentMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  hideMessage: PropTypes.func.isRequired,
  showMessage: PropTypes.bool.isRequired,
  textMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  typeMessage: PropTypes.string.isRequired,
}

Notification.defaultProps = {
  contentMessage: null,
}

export default connect(mapStateToProps, { hideMessage })(Notification)
