import { useState } from 'react'

import Authorization from 'components/Authorization'

import ItemWrapper from './ItemWrapper'

const SidenavContent = () => {
  const [selected, setSelected] = useState('')

  return (
    <ul className="nav-menu">
      {[
        {
          id: 'dashboard.sidebar',
          link: '/app/dashboard',
          icon: 'zmdi-view-dashboard',
        },
      ].map((item) => (
        <ItemWrapper key={item.id} item={item} selected={selected} setSelected={setSelected} />
      ))}
      <Authorization allowedRoles={['ROLE_SUPER_PO', 'ROLE_PO']}>
        <>
          {[
            {
              id: 'customer.customers',
              icon: 'zmdi-view-list',
              items: [
                {
                  id: 'customer.route.add',
                  link: '/app/customer/add',
                  icon: 'zmdi-plus-square',
                },
                {
                  id: 'customer.route.list',
                  link: '/app/customer/list',
                  icon: 'zmdi-view-list',
                },
                {
                  id: 'customer.route.stats',
                  link: '/app/customer/stats',
                  icon: 'zmdi-chart',
                },
              ],
            },
          ].map((item) => (
            <ItemWrapper key={item.id} item={item} selected={selected} setSelected={setSelected} />
          ))}
        </>
      </Authorization>
      <Authorization allowedRoles={['ROLE_SUPER_PO', 'ROLE_PO', 'ROLE_ADMIN']}>
        <>
          {[
            {
              id: 'user.users',
              icon: 'zmdi-accounts',
              items: [
                {
                  id: 'user.route.add',
                  icon: 'zmdi-accounts-add',
                  link: '/app/user/add',
                },
                {
                  id: 'user.route.list',
                  icon: 'zmdi-accounts-list',
                  link: '/app/user/list',
                },
              ],
            },
          ].map((item) => (
            <ItemWrapper key={item.id} item={item} selected={selected} setSelected={setSelected} />
          ))}
        </>
      </Authorization>
      <Authorization allowedRoles={['ROLE_SUPER_PO']}>
        <>
          {[
            {
              id: 'campaign.campaigns',
              icon: 'zmdi-calendar',
              items: [
                {
                  id: 'campaign.route.list',
                  icon: 'zmdi-view-list',
                  link: '/app/campaign/list',
                },
              ],
            },
          ].map((item) => (
            <ItemWrapper key={item.id} item={item} selected={selected} setSelected={setSelected} />
          ))}
        </>
      </Authorization>
      <Authorization allowedRoles={['ROLE_ADMIN']}>
        <>
          {[
            {
              id: 'campaign.campaigns',
              icon: 'zmdi-calendar',
              items: [
                {
                  id: 'campaign.route.add',
                  icon: 'zmdi-plus-square',
                  link: '/app/campaign/add',
                },
                {
                  id: 'campaign.route.list',
                  icon: 'zmdi-view-list',
                  link: '/app/campaign/list',
                },
              ],
            },
          ].map((item) => (
            <ItemWrapper key={item.id} item={item} selected={selected} setSelected={setSelected} />
          ))}
        </>
      </Authorization>
      <Authorization allowedRoles={['ROLE_ADMIN']}>
        <>
          {[
            {
              id: 'cartography.cartography',
              icon: 'zmdi-map',
              items: [
                {
                  id: 'cartography.route.visits',
                  icon: 'zmdi-pin',
                  link: '/app/cartography/visits',
                },
              ],
            },
            {
              id: 'accounting.accounting',
              icon: 'zmdi-money-box',
              items: [
                {
                  id: 'accounting.accounting.income',
                  icon: 'zmdi-calendar',
                  link: '/app/accounting/income',
                },
              ],
            },
          ].map((item) => (
            <ItemWrapper key={item.id} item={item} selected={selected} setSelected={setSelected} />
          ))}
        </>
      </Authorization>
    </ul>
  )
}

export default SidenavContent
