import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import Accounting from './Accounting'
import Auth from './Auth'
import AutocompleteFilter from './AutocompleteFilter'
import Campaign from './Campaign'
import Cartography from './Cartography'
import Customer from './Customer'
import CustomerSettings from './CustomerSettings'
import Form from './Form'
import Message from './Message'
import Settings from './Settings'
import Profile from './Profile'
import User from './User'
import Visit from './Visit'

import history from '../../store/history'

const reducers = combineReducers({
  accounting: Accounting,
  auth: Auth,
  autocompleteFilter: AutocompleteFilter,
  campaign: Campaign,
  cartography: Cartography,
  customer: Customer,
  customerSettings: CustomerSettings,
  form: Form,
  message: Message,
  router: connectRouter(history),
  settings: Settings,
  profile: Profile,
  user: User,
  visit: Visit,
})

export default reducers
