import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const acceptTermOfUse = async (authToken) => {
  const response = await fetch(`${domainName}/api/profile/accept-term-of-use`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const changePassword = async (authToken, password) => {
  const response = await fetch(`${domainName}/api/profile/change-password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password }),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const forgotPassword = async (username) => {
  const response = await fetch(`${domainName}/api/profile/forgot-password/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username }),
  })
  if (response.ok && response.status === 204) {
    return true
  }
  throw response
}

const refresh = async (authToken) => {
  const response = await fetch(`${domainName}/api/profile/refresh`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const resetPassword = async (password, token) => {
  const response = await fetch(`${domainName}/api/profile/forgot-password/${token}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password }),
  })
  if (response.ok && response.status === 204) {
    return true
  }
  throw response
}

export default {
  acceptTermOfUse,
  changePassword,
  forgotPassword,
  refresh,
  resetPassword,
}
