// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV'
export const DRAWER_TYPE = 'DRAWER_TYPE'
export const FIXED_DRAWER = 'FIXED_DRAWER'
export const COLLAPSED_DRAWER = 'COLLAPSED_DRAWER'
export const MINI_DRAWER = 'MINI_DRAWER'
export const WINDOW_WIDTH = 'WINDOW_WIDTH'
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'

// Accounting
export const ACCOUNTING_CALCULATE_START = 'ACCOUNTING_CALCULATE_START'
export const ACCOUNTING_CALCULATE_SUCCESS = 'ACCOUNTING_CALCULATE_SUCCESS'
export const ACCOUNTING_CALCULATE_FAILURE = 'ACCOUNTING_CALCULATE_FAILURE'
export const ACCOUNTING_EXPORT_XLS_START = 'ACCOUNTING_EXPORT_XLS_START'
export const ACCOUNTING_EXPORT_XLS_SUCCESS = 'ACCOUNTING_EXPORT_XLS_SUCCESS'
export const ACCOUNTING_EXPORT_XLS_FAILURE = 'ACCOUNTING_EXPORT_XLS_FAILURE'
export const ACCOUNTING_INCOME_INIT = 'ACCOUNTING_INCOME_INIT'

// Auth
export const AUTH_EXPIRE = 'AUTH_EXPIRE'
export const AUTH_EXPIRE_SUCCESS = 'AUTH_EXPIRE_SUCCESS'
export const AUTH_INIT = 'AUTH_INIT'
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS'
export const AUTH_INIT_URL = 'AUTH_INIT_URL'
export const AUTH_SIGNIN = 'AUTH_SIGNIN'
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS'
export const AUTH_SIGNIN_ERROR = 'AUTH_SIGNIN_ERROR'
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT'
export const AUTH_SIGNOUT_SUCCESS = 'AUTH_SIGNOUT_SUCCESS'

// Autocomplete filters
export const AUTOCOMPLETE_FILTERS_CAMPAIGN_START = 'AUTOCOMPLETE_FILTERS_CAMPAIGN_START'
export const AUTOCOMPLETE_FILTERS_CAMPAIGN_SUCCESS = 'AUTOCOMPLETE_FILTERS_CAMPAIGN_SUCCESS'
export const AUTOCOMPLETE_FILTERS_CAMPAIGN_ERROR = 'AUTOCOMPLETE_FILTERS_CAMPAIGN_ERROR'
export const AUTOCOMPLETE_FILTERS_USER_START = 'AUTOCOMPLETE_FILTERS_USER_START'
export const AUTOCOMPLETE_FILTERS_USER_SUCCESS = 'AUTOCOMPLETE_FILTERS_USER_SUCCESS'
export const AUTOCOMPLETE_FILTERS_USER_ERROR = 'AUTOCOMPLETE_FILTERS_USER_ERROR'

// Campaign
export const CAMPAIGN_ACTION_SHOW = 'CAMPAIGN_ACTION_SHOW'
export const CAMPAIGN_ASSIGN_SHOW = 'CAMPAIGN_ASSIGN_SHOW'
export const CAMPAIGN_ASSIGN_START = 'CAMPAIGN_ASSIGN'
export const CAMPAIGN_ASSIGNMENT_LIST = 'CAMPAIGN_ASSIGNMENT_LIST'
export const CAMPAIGN_ASSIGNMENT_LIST_SUCCESS = 'CAMPAIGN_ASSIGNMENT_LIST_SUCCESS'
export const CAMPAIGN_ASSIGNMENT_ACTION_LIST = 'CAMPAIGN_ASSIGNMENT_ACTION_LIST'
export const CAMPAIGN_ASSIGNMENT_ACTION_LIST_SUCCESS = 'CAMPAIGN_ASSIGNMENT_ACTION_LIST_SUCCESS'
export const CAMPAIGN_COLLECT_START = 'CAMPAIGN_COLLECT'
export const CAMPAIGN_COLLECT_SHOW = 'CAMPAIGN_COLLECT_SHOW'
export const CAMPAIGN_DELETE_START = 'CAMPAIGN_DELETE_START'
export const CAMPAIGN_DELETE_SUCCESS = 'CAMPAIGN_DELETE_SUCCESS'
export const CAMPAIGN_DELETE_ERROR = 'CAMPAIGN_DELETE_ERROR'
export const CAMPAIGN_FETCH_DATA = 'CAMPAIGN_FETCH_DATA'
export const CAMPAIGN_FETCH_DATA_SUCCESS = 'CAMPAIGN_FETCH_DATA_SUCCESS'
export const CAMPAIGN_FETCH_FORM = 'CAMPAIGN_FETCH_FORM'
export const CAMPAIGN_FETCH_FORM_SUCCESS = 'CAMPAIGN_FETCH_FORM_SUCCESS'
export const CAMPAIGN_FETCH_FORM_ERROR = 'CAMPAIGN_FETCH_FORM_ERROR'
export const CAMPAIGN_FETCH_STATS_START = 'CAMPAIGN_FETCH_STATS_START'
export const CAMPAIGN_FETCH_STATS_SUCCESS = 'CAMPAIGN_FETCH_STATS_SUCCESS'
export const CAMPAIGN_FETCH_STATS_ERROR = 'CAMPAIGN_FETCH_STATS_ERROR'
export const CAMPAIGN_LOST_SHOW = 'CAMPAIGN_LOST_SHOW'
export const CAMPAIGN_LOST_START = 'CAMPAIGN_LOST_START'
export const CAMPAIGN_POPIN_HIDE = 'CAMPAIGN_POPIN_HIDE'
export const CAMPAIGN_POST_FORM = 'CAMPAIGN_POST_FORM'
export const CAMPAIGN_POST_FORM_SUCCESS = 'CAMPAIGN_POST_FORM_SUCCESS'
export const CAMPAIGN_POST_FORM_ERROR = 'CAMPAIGN_POST_FORM_ERROR'
export const CAMPAIGN_LIST = 'CAMPAIGN_LIST'
export const CAMPAIGN_LIST_SUCCESS = 'CAMPAIGN_LIST_SUCCESS'
export const CAMPAIGN_CLOSE = 'CAMPAIGN_CLOSE'
export const CAMPAIGN_CLOSE_SUCCESS = 'CAMPAIGN_CLOSE_SUCCESS'
export const CAMPAIGN_VISIT_EXPORT_START = 'CAMPAIGN_VISIT_EXPORT_START'
export const CAMPAIGN_VISIT_EXPORT_SUCCESS = 'CAMPAIGN_VISIT_EXPORT_SUCCESS'
export const CAMPAIGN_VISIT_EXPORT_FAILURE = 'CAMPAIGN_VISIT_EXPORT_FAILURE'
export const CAMPAIGN_VISIT_LIST_START = 'CAMPAIGN_VISIT_LIST_START'
export const CAMPAIGN_VISIT_LIST_SUCCESS = 'CAMPAIGN_VISIT_LIST_SUCCESS'
export const CAMPAIGN_VISIT_LIST_FAILURE = 'CAMPAIGN_VISIT_LIST_FAILURE'
export const CAMPAIGN_VISIT_UPDATE_START = 'CAMPAIGN_VISIT_UPDATE_START'
export const CAMPAIGN_VISIT_UPDATE_SUCCESS = 'CAMPAIGN_VISIT_UPDATE_SUCCESS'
export const CAMPAIGN_VISIT_UPDATE_FAILURE = 'CAMPAIGN_VISIT_UPDATE_FAILURE'

// Cartography
export const CARTOGRAPHY_FETCH_MAP_VISITS_START = 'CARTOGRAPHY_FETCH_MAP_VISITS_START'
export const CARTOGRAPHY_FETCH_MAP_VISITS_SUCCESS = 'CARTOGRAPHY_FETCH_MAP_VISITS_SUCCESS'
export const CARTOGRAPHY_FETCH_MAP_VISITS_FAILURE = 'CARTOGRAPHY_FETCH_MAP_VISITS_FAILURE'
export const CARTOGRAPHY_SET_FILTERS_START = 'CARTOGRAPHY_SET_FILTERS_START'
export const CARTOGRAPHY_SET_MAP_CURRENT_POSITION_START = 'CARTOGRAPHY_SET_MAP_CURRENT_POSITION_START'
export const CARTOGRAPHY_SET_MAP_CURRENT_POSITION_SUCCESS = 'CARTOGRAPHY_SET_MAP_CURRENT_POSITION_SUCCESS'
export const CARTOGRAPHY_SET_MAP_CURRENT_POSITION_ERROR = 'CARTOGRAPHY_SET_MAP_CURRENT_POSITION_ERROR'
export const CARTOGRAPHY_SET_MAP_CURRENT_ZOOM_SUCCESS = 'CARTOGRAPHY_SET_MAP_CURRENT_ZOOM_SUCCESS'

// Customer
export const CUSTOMER_DISABLE_START = 'CUSTOMER_DISABLE_START'
export const CUSTOMER_DISABLE_SUCCESS = 'CUSTOMER_DISABLE_SUCCESS'
export const CUSTOMER_DISABLE_FAILURE = 'CUSTOMER_DISABLE_FAILURE'
export const CUSTOMER_ENABLE_START = 'CUSTOMER_ENABLE_START'
export const CUSTOMER_ENABLE_SUCCESS = 'CUSTOMER_ENABLE_SUCCESS'
export const CUSTOMER_ENABLE_FAILURE = 'CUSTOMER_ENABLE_FAILURE'
export const CUSTOMER_FETCH_CONNECT_LINK_START = 'CUSTOMER_FETCH_CONNECT_LINK_START'
export const CUSTOMER_FETCH_CONNECT_LINK_END = 'CUSTOMER_FETCH_CONNECT_LINK_END'
export const CUSTOMER_FETCH_FORM_START = 'CUSTOMER_FETCH_FORM_START'
export const CUSTOMER_FETCH_FORM_SUCCESS = 'CUSTOMER_FETCH_FORM_SUCCESS'
export const CUSTOMER_POST_FORM_START = 'CUSTOMER_POST_FORM_START'
export const CUSTOMER_POST_FORM_SUCCESS = 'CUSTOMER_POST_FORM_SUCCESS'
export const CUSTOMER_POST_FORM_ERROR = 'CUSTOMER_POST_FORM_ERROR'
export const CUSTOMER_UPLOAD_PHOTO_START = 'CUSTOMER_UPLOAD_PHOTO_START'
export const CUSTOMER_UPLOAD_PHOTO_SUCCESS = 'CUSTOMER_UPLOAD_PHOTO_SUCCESS'
export const CUSTOMER_UPLOAD_PHOTO_FAILURE = 'CUSTOMER_UPLOAD_PHOTO_FAILURE'
export const CUSTOMER_LIST_START = 'CUSTOMER_LIST_START'
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS'
export const CUSTOMER_STATS_START = 'CUSTOMER_STATS_START'
export const CUSTOMER_STATS_SUCCESS = 'CUSTOMER_STATS_SUCCESS'
export const CUSTOMER_STATS_ERROR = 'CUSTOMER_STATS_ERROR'

// Customer settings
export const CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START = 'CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_START'
export const CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_SUCCESS = 'CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_SUCCESS'
export const CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_ERROR = 'CUSTOMER_SETTINGS_UPDATE_DEFAULT_POSITION_ERROR'
export const CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START = 'CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_START'
export const CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_SUCCESS = 'CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_SUCCESS'
export const CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_ERROR = 'CUSTOMER_SETTINGS_UPDATE_DEFAULT_ZOOM_ERROR'

// Error
export const HANDLE_ERROR_API = 'HANDLE_ERROR_API'

// Form
export const FORM_RESET_ERROR = 'FORM_RESET_ERROR'
export const FORM_STORE_ERROR = 'FORM_STORE_ERROR'

// Notification const
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'

// Profile
export const PROFILE_ACCEPT_TOU_START = 'PROFILE_ACCEPT_TOU_START'
export const PROFILE_ACCEPT_TOU_SUCCESS = 'PROFILE_ACCEPT_TOU_SUCCESS'
export const PROFILE_ACCEPT_TOU_FAILURE = 'PROFILE_ACCEPT_TOU_FAILURE'
export const PROFILE_CHANGE_PASSWORD_INIT = 'PROFILE_CHANGE_PASSWORD_INIT'
export const PROFILE_CHANGE_PASSWORD_START = 'PROFILE_CHANGE_PASSWORD_START'
export const PROFILE_CHANGE_PASSWORD_SUCCESS = 'PROFILE_CHANGE_PASSWORD_SUCCESS'
export const PROFILE_CHANGE_PASSWORD_FAILURE = 'PROFILE_CHANGE_PASSWORD_FAILURE'
export const PROFILE_FORGOT_PASSWORD_START = 'PROFILE_FORGOT_PASSWORD_START'
export const PROFILE_FORGOT_PASSWORD_SUCCESS = 'PROFILE_FORGOT_PASSWORD_SUCCESS'
export const PROFILE_FORGOT_PASSWORD_FAILTURE = 'PROFILE_FORGOT_PASSWORD_FAILTURE'
export const PROFILE_REFRESH_START = 'PROFILE_REFRESH_START'
export const PROFILE_REFRESH_SUCCESS = 'PROFILE_REFRESH_SUCCESS'
export const PROFILE_REFRESH_FAILURE = 'PROFILE_REFRESH_FAILURE'
export const PROFILE_RESET_PASSWORD = 'PROFILE_RESET_PASSWORD'
export const PROFILE_RESET_PASSWORD_SUCCESS = 'PROFILE_RESET_PASSWORD_SUCCESS'
export const PROFILE_RESET_PASSWORD_ERROR = 'PROFILE_RESET_PASSWORD_ERROR'

// User
export const USER_DELETE_START = 'USER_DELETE_START'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR'
export const USER_DISABLE_START = 'USER_DISABLE_START'
export const USER_DISABLE_SUCCESS = 'USER_DISABLE_SUCCESS'
export const USER_DISABLE_FAILURE = 'USER_DISABLE_FAILURE'
export const USER_ENABLE_START = 'USER_ENABLE_START'
export const USER_ENABLE_SUCCESS = 'USER_ENABLE_SUCCESS'
export const USER_ENABLE_FAILURE = 'USER_ENABLE_FAILURE'
export const USER_FETCH_FORM = 'USER_FETCH_FORM'
export const USER_FETCH_FORM_SUCCESS = 'USER_FETCH_FORM_SUCCESS'
export const USER_FETCH_FORM_ERROR = 'USER_FETCH_FORM_ERROR'
export const USER_LIST_START = 'USER_LIST_START'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_POST_FORM = 'USER_POST_FORM'
export const USER_POST_FORM_SUCCESS = 'USER_POST_FORM_SUCCESS'
export const USER_POST_FORM_ERROR = 'USER_POST_FORM_ERROR'
export const USER_UNVALIDATE = 'USER_UNVALIDATE'
export const USER_UNVALIDATE_SUCCESS = 'USER_UNVALIDATE_SUCCESS'
export const USER_VALIDATE = 'USER_VALIDATE'
export const USER_VALIDATE_SUCCESS = 'USER_VALIDATE_SUCCESS'

// Visit
export const VISIT_LAST_VISITS_START = 'VISIT_LAST_VISITS_START'
export const VISIT_LAST_VISITS_SUCCESS = 'VISIT_LAST_VISITS_SUCCESS'
export const VISIT_LAST_VISITS_FAILURE = 'VISIT_LAST_VISITS_FAILURE'
