import accounting from './accounting'
import auth from './auth'
import campaign from './campaign'
import cartography from './cartography'
import customer from './customer'
import customerSettings from './customerSettings'
import profile from './profile'
import user from './user'
import visit from './visit'

const API = {
  accounting,
  auth,
  campaign,
  cartography,
  customer,
  customerSettings,
  profile,
  user,
  visit,
}

export default API
