import { FORM_RESET_ERROR, FORM_STORE_ERROR } from 'redux/ActionTypes'

const INIT_STATE = {
  formsErrors: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORM_RESET_ERROR: {
      const { componentId } = action.payload
      return {
        ...state,
        formsErrors: state.formsErrors.filter((item) => item.id !== componentId),
      }
    }
    case FORM_STORE_ERROR: {
      const { componentId, errors } = action.payload

      // Replace
      if (state.formsErrors.filter((item) => item.id === componentId).length) {
        return {
          ...state,
          formsErrors: state.formsErrors.map((item) => {
            if (item.id === componentId) {
              return {
                ...item,
                errors,
              }
            }

            return item
          }),
        }
      }

      // Add
      return {
        ...state,
        formsErrors: [...state.formsErrors, { id: componentId, errors }],
      }
    }
    default:
      return state
  }
}
