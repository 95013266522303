import {
  PROFILE_ACCEPT_TOU_START,
  PROFILE_ACCEPT_TOU_SUCCESS,
  PROFILE_ACCEPT_TOU_FAILURE,
  PROFILE_CHANGE_PASSWORD_INIT,
  PROFILE_CHANGE_PASSWORD_START,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
  PROFILE_CHANGE_PASSWORD_FAILURE,
  PROFILE_REFRESH_START,
  PROFILE_REFRESH_SUCCESS,
  PROFILE_REFRESH_FAILURE,
  PROFILE_RESET_PASSWORD_SUCCESS,
  PROFILE_RESET_PASSWORD_ERROR,
  PROFILE_FORGOT_PASSWORD_START,
  PROFILE_FORGOT_PASSWORD_SUCCESS,
  PROFILE_FORGOT_PASSWORD_FAILTURE,
  PROFILE_RESET_PASSWORD,
} from 'redux/ActionTypes'

export const profileAcceptTermOfUse = () => ({
  type: PROFILE_ACCEPT_TOU_START,
})

export const profileAcceptTermOfUseSuccess = (authUser) => ({
  type: PROFILE_ACCEPT_TOU_SUCCESS,
  authUser,
})

export const profileAcceptTermOfUseFailure = () => ({
  type: PROFILE_ACCEPT_TOU_FAILURE,
})

export const profileChangePasswordInit = () => ({
  type: PROFILE_CHANGE_PASSWORD_INIT,
})

export const profileChangePasswordStart = (password) => ({
  type: PROFILE_CHANGE_PASSWORD_START,
  payload: { password },
})

export const profileChangePasswordSuccess = (authUser) => ({
  type: PROFILE_CHANGE_PASSWORD_SUCCESS,
  authUser,
})

export const profileChangePasswordFailure = () => ({
  type: PROFILE_CHANGE_PASSWORD_FAILURE,
})

export const profileForgotPasswordStart = (credentials) => ({
  type: PROFILE_FORGOT_PASSWORD_START,
  payload: credentials,
})

export const profileForgotPasswordSuccess = () => ({
  type: PROFILE_FORGOT_PASSWORD_SUCCESS,
})

export const profileForgotPasswordFailture = () => ({
  type: PROFILE_FORGOT_PASSWORD_FAILTURE,
})

export const profileRefreshStart = () => ({
  type: PROFILE_REFRESH_START,
})

export const profileRefreshSuccess = (authUser) => ({
  type: PROFILE_REFRESH_SUCCESS,
  payload: { authUser },
})

export const profileRefreshFailure = () => ({
  type: PROFILE_REFRESH_FAILURE,
})

export const profileResetPasswordStart = (payload) => ({
  type: PROFILE_RESET_PASSWORD,
  payload,
})

export const profileResetPasswordSuccess = () => ({
  type: PROFILE_RESET_PASSWORD_SUCCESS,
})

export const profileResetPasswordError = () => ({
  type: PROFILE_RESET_PASSWORD_ERROR,
})
