import {
  AUTH_INIT_SUCCESS,
  AUTH_INIT_URL,
  AUTH_SIGNIN,
  AUTH_SIGNIN_ERROR,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNOUT,
  AUTH_SIGNOUT_SUCCESS,
  AUTH_EXPIRE,
  AUTH_EXPIRE_SUCCESS,
} from 'redux/ActionTypes'

const INIT_STATE = {
  alertMessage: '',
  authToken: localStorage.getItem('auth_token_encoded'),
  decodedToken: JSON.parse(localStorage.getItem('auth_token_decoded')),
  initURL: '',
  lastConnection: {
    trigram: '',
    staffNumber: '',
  },
  loader: false,
  loaderSignOut: false,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_EXPIRE: {
      return {
        ...state,
        loader: true,
      }
    }
    case AUTH_EXPIRE_SUCCESS: {
      return {
        ...state,
        authToken: null,
        decodedToken: null,
        loader: false,
      }
    }
    case AUTH_INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      }
    }
    case AUTH_INIT_SUCCESS:
      return {
        ...state,
        lastConnection: action.lastConnection,
        loader: false,
      }
    case AUTH_SIGNIN: {
      return {
        ...state,
        loader: true,
      }
    }
    case AUTH_SIGNIN_ERROR: {
      return {
        ...state,
        loader: false,
      }
    }
    case AUTH_SIGNIN_SUCCESS: {
      return {
        ...state,
        loader: false,
        authToken: action.payload.encodedToken,
        decodedToken: action.payload.decodedToken,
      }
    }
    case AUTH_SIGNOUT: {
      return {
        ...state,
        loaderSignOut: true,
      }
    }
    case AUTH_SIGNOUT_SUCCESS: {
      return {
        ...state,
        authToken: null,
        decodedToken: null,
        initURL: '/app/dashboard',
        loaderSignOut: false,
      }
    }
    default:
      return state
  }
}
