import * as Yup from 'yup'

import frLang from './entries/fr_FR'
import yupFr from './yup/fr_FR'

Yup.setLocale(yupFr)

const AppLocale = {
  fr: frLang,
}

export default AppLocale
