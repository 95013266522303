import PropTypes from 'prop-types'

const CardBox = ({ heading, subHeading, children, styleName, cardStyle, childrenStyle }) => (
  <div className={`${styleName}`}>
    <div className={`jr-card ${cardStyle}`}>
      {heading && (
        <div className="jr-card-header">
          <h3 className="card-heading">{heading}</h3>
          {subHeading && <div className="sub-heading">{subHeading}</div>}
        </div>
      )}
      <div className={`jr-card-body ${childrenStyle}`}>{children}</div>
    </div>
  </div>
)

CardBox.propTypes = {
  cardStyle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  childrenStyle: PropTypes.string,
  heading: PropTypes.shape({}),
  subHeading: PropTypes.shape({}),
  styleName: PropTypes.string,
}

CardBox.defaultProps = {
  cardStyle: '',
  childrenStyle: '',
  heading: null,
  subHeading: null,
  styleName: 'col-lg-6 col-sm-12',
}

export default CardBox
