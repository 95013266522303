import {
  CUSTOMER_DISABLE_START,
  CUSTOMER_DISABLE_SUCCESS,
  CUSTOMER_DISABLE_FAILURE,
  CUSTOMER_ENABLE_START,
  CUSTOMER_ENABLE_SUCCESS,
  CUSTOMER_ENABLE_FAILURE,
  CUSTOMER_FETCH_CONNECT_LINK_START,
  CUSTOMER_FETCH_CONNECT_LINK_END,
  CUSTOMER_FETCH_FORM_START,
  CUSTOMER_FETCH_FORM_SUCCESS,
  CUSTOMER_POST_FORM_START,
  CUSTOMER_POST_FORM_SUCCESS,
  CUSTOMER_POST_FORM_ERROR,
  CUSTOMER_UPLOAD_PHOTO_START,
  CUSTOMER_UPLOAD_PHOTO_SUCCESS,
  CUSTOMER_UPLOAD_PHOTO_FAILURE,
  CUSTOMER_LIST_START,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_STATS_START,
  CUSTOMER_STATS_SUCCESS,
  CUSTOMER_STATS_ERROR,
} from 'redux/ActionTypes'

export const disableCustomerStart = (id) => ({
  type: CUSTOMER_DISABLE_START,
  payload: { id },
})

export const disableCustomerSuccess = (customer) => ({
  type: CUSTOMER_DISABLE_SUCCESS,
  payload: customer,
})

export const disableCustomerFailure = () => ({
  type: CUSTOMER_DISABLE_FAILURE,
})

export const enableCustomerStart = (id) => ({
  type: CUSTOMER_ENABLE_START,
  payload: { id },
})

export const enableCustomerSuccess = (customer) => ({
  type: CUSTOMER_ENABLE_SUCCESS,
  payload: customer,
})

export const enableCustomerFailure = () => ({
  type: CUSTOMER_ENABLE_FAILURE,
})

export const fetchConnectLinkStart = () => ({
  type: CUSTOMER_FETCH_CONNECT_LINK_START,
})

export const fetchConnectLinkEnd = (fetchedConnectLink) => ({
  type: CUSTOMER_FETCH_CONNECT_LINK_END,
  payload: { fetchedConnectLink },
})

export const fetchFormStart = (formName, customerId) => ({
  type: CUSTOMER_FETCH_FORM_START,
  payload: { formName, customerId },
})

export const fetchFormSuccess = (formDefinition) => ({
  type: CUSTOMER_FETCH_FORM_SUCCESS,
  payload: { formDefinition },
})

export const postFormStart = (formName, id, values, setSubmitting) => ({
  type: CUSTOMER_POST_FORM_START,
  payload: { formName, id, values, setSubmitting },
})

export const postFormSuccess = (form) => ({
  type: CUSTOMER_POST_FORM_SUCCESS,
  payload: form,
})

export const postFormError = () => ({
  type: CUSTOMER_POST_FORM_ERROR,
})

export const uploadPhotoStart = (id, file) => ({
  type: CUSTOMER_UPLOAD_PHOTO_START,
  payload: { id, file },
})

export const uploadPhotoSuccess = (customerData) => ({
  type: CUSTOMER_UPLOAD_PHOTO_SUCCESS,
  payload: { customerData },
})

export const uploadPhotoFailure = () => ({
  type: CUSTOMER_UPLOAD_PHOTO_FAILURE,
})

export const listStart = (parameters) => ({
  type: CUSTOMER_LIST_START,
  payload: { parameters },
})

export const listSuccess = (data) => ({
  type: CUSTOMER_LIST_SUCCESS,
  payload: data,
})

export const statsStart = (startDate, endDate) => ({
  type: CUSTOMER_STATS_START,
  payload: { startDate, endDate },
})

export const statsSuccess = (form) => ({
  type: CUSTOMER_STATS_SUCCESS,
  payload: form,
})

export const statsError = () => ({
  type: CUSTOMER_STATS_ERROR,
})
