import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const deleteUser = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/users/delete`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
  })
  if (response.ok) {
    return id
  }
  throw response
}

const disable = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/users/${id}/disable`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return getApiMessage(response)
  }
  throw response
}

const enable = async (authToken, id) => {
  const response = await fetch(`${domainName}/api/users/${id}/enable`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return getApiMessage(response)
  }
  throw response
}

const fetchFormAdd = async (authToken) => {
  const response = await fetch(`${domainName}/api/users/form`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return {
      initialValues: json.initialValues,
      schema: json.schema,
    }
  }
  throw response
}

const fetchFormEdit = async (authToken, userId) => {
  const response = await fetch(`${domainName}/api/users/form/${encodeURI(userId)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return {
      initialValues: json.initialValues,
      schema: json.schema,
      userData: json.userData,
    }
  }
  throw response
}

const listRequest = async (authToken, parameters) => {
  const response = await fetch(`${domainName}/api/users/?${encodeURI(parameters)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const postFormAdd = async (authToken, values) => {
  const response = await fetch(`${domainName}/api/users/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const postFormEdit = async (authToken, userId, values) => {
  const response = await fetch(`${domainName}/api/users/${encodeURI(userId)}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const search = async (authToken, text) => {
  const response = await fetch(`${domainName}/api/users/search/${encodeURI(text)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

const unvalidate = async (authToken, userId) => {
  const response = await fetch(`${domainName}/api/users/${encodeURI(userId)}/unvalidate`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (!response.ok) {
    throw response
  }
}

const validate = async (authToken, userId) => {
  const response = await fetch(`${domainName}/api/users/${encodeURI(userId)}/validate`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

export default {
  deleteUser,
  disable,
  enable,
  fetchFormAdd,
  fetchFormEdit,
  listRequest,
  postFormAdd,
  postFormEdit,
  search,
  unvalidate,
  validate,
}
