import { axiosRequest, domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const calculate = async (authToken, campaigns) => {
  const response = await fetch(`${domainName}/api/accounting/income/calculate`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(campaigns),
  })
  if (response.ok) {
    return getApiMessage(response)
  }
  throw response
}

const exportXls = async (authToken, campaigns) => {
  const response = await axiosRequest.post('/api/accounting/income/export', campaigns, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    responseType: 'blob',
  })
  if (response.status === 200) {
    return response.data
  }
  throw response
}

export default {
  calculate,
  exportXls,
}
