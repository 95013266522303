const hasRole = (allowedRole, roles) => roles.includes(allowedRole)

const hasRoles = (allowedRoles, roles) => {
  const allowed = allowedRoles.reduce((acc, currValue) => {
    if (acc || roles.includes(currValue)) {
      return true
    }
    return false
  }, false)

  return allowed
}

export { hasRole, hasRoles }

export default hasRole
