import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const fetchLastVisits = async (authToken) => {
  const response = await fetch(`${domainName}/api/visits/last`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return getApiMessage(response)
  }
  throw response
}

const update = async (authToken, id, values) => {
  const response = await fetch(`${domainName}/api/visits/${encodeURI(id)}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

export default {
  fetchLastVisits,
  update,
}
