import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import {
  disableCustomerSuccess,
  disableCustomerFailure,
  enableCustomerSuccess,
  enableCustomerFailure,
  fetchConnectLinkEnd,
  fetchFormSuccess,
  listSuccess,
  postFormSuccess,
  postFormError,
  uploadPhotoSuccess,
  uploadPhotoFailure,
  statsSuccess,
  statsError,
} from 'redux/actions/Customer'
import { handleErrorApi } from 'redux/actions/Error'
import { resetFormError } from 'redux/actions/Form'
import { showMessage } from 'redux/actions/Message'
import {
  CUSTOMER_DISABLE_START,
  CUSTOMER_ENABLE_START,
  CUSTOMER_FETCH_CONNECT_LINK_START,
  CUSTOMER_FETCH_FORM_START,
  CUSTOMER_POST_FORM_START,
  CUSTOMER_LIST_START,
  CUSTOMER_STATS_START,
  CUSTOMER_UPLOAD_PHOTO_START,
} from 'redux/ActionTypes'

import { getToken } from './Auth'
import API from '../../API'

function* disable({ payload }) {
  try {
    const { id } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.customer.disable, authToken, id)
    yield put(enableCustomerSuccess(response))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(enableCustomerFailure())
  }
}

function* enable({ payload }) {
  try {
    const { id } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.customer.enable, authToken, id)
    yield put(disableCustomerSuccess(response))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(disableCustomerFailure())
  }
}

function* fetchConnectLink() {
  try {
    const authToken = yield select(getToken)
    const response = yield call(API.customer.fetchConnectLink, authToken)

    yield put(fetchConnectLinkEnd(response))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* fetchForm({ payload }) {
  try {
    const { formName, customerId } = payload
    const authToken = yield select(getToken)
    let response
    if (customerId) {
      response = yield call(API.customer.fetchFormEdit, authToken, customerId)
    } else {
      response = yield call(API.customer.fetchFormAdd, authToken)
    }
    yield put(resetFormError(formName))
    yield put(fetchFormSuccess(response))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* list({ payload }) {
  try {
    const { parameters } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.customer.listRequest, authToken, parameters)
    yield put(listSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* postForm({ payload }) {
  const { formName, id, values } = payload
  try {
    const authToken = yield select(getToken)
    let response

    if (id) {
      response = yield call(API.customer.postFormEdit, authToken, id, values)
    } else {
      response = yield call(API.customer.postFormAdd, authToken, values)
    }
    yield put(resetFormError(formName))
    yield put(postFormSuccess(response))
    yield put(showMessage('form.success'))
    yield put(push('/app/customer/list'))
  } catch (error) {
    yield put(handleErrorApi(error, formName))
    yield put(postFormError())
  } finally {
    const { setSubmitting } = payload
    setSubmitting(false)
  }
}

function* stats({ payload }) {
  try {
    const { startDate, endDate } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.customer.statsRequest, authToken, startDate, endDate)
    yield put(statsSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(statsError())
  }
}

function* uploadPhoto({ payload }) {
  try {
    const { id, file } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.customer.uploadPhoto, authToken, id, file)
    yield put(uploadPhotoSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(uploadPhotoFailure())
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(CUSTOMER_DISABLE_START, disable),
    takeEvery(CUSTOMER_ENABLE_START, enable),
    takeEvery(CUSTOMER_FETCH_CONNECT_LINK_START, fetchConnectLink),
    takeEvery(CUSTOMER_FETCH_FORM_START, fetchForm),
    takeEvery(CUSTOMER_LIST_START, list),
    takeEvery(CUSTOMER_POST_FORM_START, postForm),
    takeEvery(CUSTOMER_STATS_START, stats),
    takeEvery(CUSTOMER_UPLOAD_PHOTO_START, uploadPhoto),
  ])
}
