import {
  USER_DELETE_START,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_DISABLE_START,
  USER_DISABLE_SUCCESS,
  USER_DISABLE_FAILURE,
  USER_ENABLE_START,
  USER_ENABLE_SUCCESS,
  USER_ENABLE_FAILURE,
  USER_FETCH_FORM,
  USER_FETCH_FORM_SUCCESS,
  USER_FETCH_FORM_ERROR,
  USER_POST_FORM_SUCCESS,
  USER_LIST_SUCCESS,
  USER_UNVALIDATE,
  USER_UNVALIDATE_SUCCESS,
  USER_VALIDATE,
  USER_VALIDATE_SUCCESS,
} from 'redux/ActionTypes'

const INIT_STATE = {
  initialValues: null,
  message: '',
  rawDataTablesResponse: {
    data: [],
    draw: 0,
    error: '',
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  schema: null,
  loader: false,
  userPendingActions: [],
  userData: null,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_DELETE_START: {
      return {
        ...state,
        userPendingActions: [...state.userPendingActions, { id: action.payload.id, status: 'PENDING_DELETE' }],
      }
    }
    case USER_DELETE_SUCCESS: {
      return {
        ...state,
        userPendingActions: state.userPendingActions.map((p) =>
          p.id === action.payload.id ? { ...p, status: 'DELETED' } : p,
        ),
        rawDataTablesResponse: {
          ...state.rawDataTablesResponse,
          data: state.rawDataTablesResponse.data.filter((d) => d['u.id'] !== action.payload.id),
        },
      }
    }
    case USER_DELETE_ERROR: {
      return {
        ...state,
        userPendingActions: state.userPendingActions.filter((p) => p.id !== action.payload.id),
      }
    }
    case USER_DISABLE_START:
    case USER_ENABLE_START:
      return {
        ...state,
        userPendingActions: [...state.userPendingActions, { id: action.payload.id, status: 'PENDING_ENABLE' }],
      }
    case USER_DISABLE_SUCCESS:
    case USER_ENABLE_SUCCESS:
      return {
        ...state,
        userPendingActions: state.userPendingActions.filter((p) => p.id !== action.payload.id),
        rawDataTablesResponse: {
          ...state.rawDataTablesResponse,
          data: state.rawDataTablesResponse.data.map((d) => {
            if (d['u.id'] === action.payload.id) {
              const newd = d
              newd['u.enabled'] = action.payload.enabled
              return newd
            }
            return d
          }),
        },
      }
    case USER_DISABLE_FAILURE:
    case USER_ENABLE_FAILURE:
      return {
        ...state,
        userPendingActions: state.userPendingActions.filter((p) => p.id !== action.payload.id),
      }
    case USER_FETCH_FORM: {
      return {
        ...state,
        userData: null,
        initialValues: null,
        schema: null,
        loader: true,
      }
    }
    case USER_FETCH_FORM_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: action.payload.formDefinition.userData,
        initialValues: action.payload.formDefinition.initialValues,
        schema: action.payload.formDefinition.schema,
      }
    }
    case USER_FETCH_FORM_ERROR: {
      return {
        ...state,
        loader: false,
      }
    }
    case USER_LIST_SUCCESS: {
      return {
        ...state,
        rawDataTablesResponse: action.payload,
      }
    }
    case USER_POST_FORM_SUCCESS: {
      return {
        ...state,
        message: action.payload.message,
      }
    }
    case USER_UNVALIDATE: {
      return {
        ...state,
        loader: true,
      }
    }
    case USER_UNVALIDATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: action.payload.userData,
      }
    }
    case USER_VALIDATE: {
      return {
        ...state,
        loader: true,
      }
    }
    case USER_VALIDATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: action.payload.userData,
      }
    }
    default:
      return state
  }
}
