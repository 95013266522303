import * as Sentry from '@sentry/browser'

const initSentry = () => {
  if (process.env.RELEASE !== 'dev') Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

const captureException = (error) => {
  // eslint-disable-next-line no-undef, no-console
  if (process.env.RELEASE === 'dev') console.warn(error)
  else Sentry.captureException(error)
}

const captureMessage = (data) => {
  // eslint-disable-next-line no-undef, no-console
  if (process.env.RELEASE === 'dev') console.warn(data)
  else {
    const message = typeof data === 'string' ? data : JSON.stringify(data)
    Sentry.captureMessage(message)
  }
}

const defineUser = (user) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user ? { id: user.id, username: user.username } : { id: null, username: 'anonymous' })
  })
}

const showReportDialog = () => {
  Sentry.showReportDialog({ title: 'Signaler un bug', subtitle: '' })
}

export { captureException, captureMessage, defineUser, initSentry, showReportDialog }
