import ExtendableError from './ExtendableError'

class ApiError extends ExtendableError {
  constructor(status, statusText) {
    super(statusText)
    this.status = status
    this.statusText = statusText
  }
}

export default ApiError
