import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import JwtDecode from 'jwt-decode'

import {
  authExpireSuccess,
  authInitSuccess,
  authSignInSuccess,
  authSignInError,
  authSignOutSuccess,
} from 'redux/actions/Auth'
import { handleErrorApi } from 'redux/actions/Error'
import { showErrorMessage } from 'redux/actions/Message'
import { AUTH_EXPIRE, AUTH_INIT, AUTH_SIGNIN, AUTH_SIGNOUT } from 'redux/ActionTypes'
import { ERROR_NO_TOKEN } from 'constants/JsonResponse'
import { defineUser } from 'util/Sentry'

import API from '../../API'

export const getToken = (state) => state.auth.authToken
export const getInitUrl = (state) => state.auth.initURL

const cleanAuthLocalStorage = () => {
  localStorage.removeItem('auth_token_encoded')
  localStorage.removeItem('auth_token_decoded')
  localStorage.removeItem('auth_user')
}

function* expireSaga() {
  try {
    yield call(cleanAuthLocalStorage)
    yield put(authExpireSuccess())
  } catch (error) {
    yield put(showErrorMessage(error.statusText))
  }
}

function* initSaga() {
  try {
    const lastConnection = JSON.parse(localStorage.getItem('auth_last_connection'))
    if (lastConnection) {
      yield put(authInitSuccess(lastConnection))
    }
  } catch (error) {
    console.error(error)
  }
}

function* signInSaga({ payload }) {
  try {
    const { trigram, staffNumber, password } = payload
    localStorage.setItem('auth_last_connection', JSON.stringify({ trigram, staffNumber }))
    const username = `${trigram.trim()}${staffNumber.trim()}`
    const responseJson = yield call(API.auth.authenticate, username, password.trim())
    if (responseJson.token && responseJson.token !== '') {
      const decodedToken = JwtDecode(responseJson.token)
      localStorage.setItem('auth_token_encoded', responseJson.token)
      localStorage.setItem('auth_token_decoded', JSON.stringify(decodedToken))
      localStorage.setItem('auth_user', JSON.stringify(decodedToken.user))
      defineUser(decodedToken.user)
      yield put(
        authSignInSuccess({
          encodedToken: responseJson.token,
          decodedToken,
          user: decodedToken.user,
        }),
      )
      const initURL = yield select(getInitUrl)
      if (initURL !== '') {
        yield put(push(initURL))
      }
    } else {
      yield put(showErrorMessage(ERROR_NO_TOKEN))
    }
  } catch (error) {
    yield put(authSignInError())
    if (error.status && error.status === 401) {
      yield put(showErrorMessage('auth.error'))
    } else {
      yield put(handleErrorApi(error))
    }
  }
}

function* signOutSaga() {
  try {
    yield call(cleanAuthLocalStorage)
    yield put(authSignOutSuccess())
    yield put(push('/'))
    defineUser(null)
  } catch (error) {
    yield put(showErrorMessage(error))
  }
}

export function* authExpire() {
  yield takeEvery(AUTH_EXPIRE, expireSaga)
}

export function* authInit() {
  yield takeEvery(AUTH_INIT, initSaga)
}

export function* authSignIn() {
  yield takeEvery(AUTH_SIGNIN, signInSaga)
}

export function* authSignOut() {
  yield takeEvery(AUTH_SIGNOUT, signOutSaga)
}

export default function* rootSaga() {
  yield all([fork(authInit), fork(authExpire), fork(authSignIn), fork(authSignOut)])
}
