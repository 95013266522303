import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import MainApp from './MainApp'

import { initReactGA } from './util/analytics'
import { initSentry } from './util/Sentry'

initSentry()
initReactGA()

ReactDOM.render(
  <StrictMode>
    <MainApp />
  </StrictMode>,
  document.getElementById('app-site'),
)
