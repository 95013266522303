import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { handleErrorApi } from 'redux/actions/Error'
import { resetFormError } from 'redux/actions/Form'
import { showMessage, showInfoMessage } from 'redux/actions/Message'

import {
  userDeleteSuccess,
  userDeleteError,
  disableUserSuccess,
  disableUserFailure,
  enableUserSuccess,
  enableUserFailure,
  userFetchFormSuccess,
  userFetchFormError,
  userListSuccess,
  userPostFormError,
  userPostFormSuccess,
  userUnvalidateSuccess,
  userValidateSuccess,
} from 'redux/actions/User'
import {
  USER_DELETE_START,
  USER_DISABLE_START,
  USER_ENABLE_START,
  USER_FETCH_FORM,
  USER_LIST_START,
  USER_POST_FORM,
  USER_UNVALIDATE,
  USER_VALIDATE,
} from 'redux/ActionTypes'

import API from '../../API'
import { getToken } from './Auth'

function* userDeleteSaga({ payload }) {
  const { id } = payload
  try {
    const authToken = yield select(getToken)
    yield call(API.user.deleteUser, authToken, id)
    yield put(userDeleteSuccess(id))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(userDeleteError(id))
  }
}

function* disable({ payload }) {
  try {
    const { id } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.user.disable, authToken, id)
    yield put(disableUserSuccess(response))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(disableUserFailure())
  }
}

function* enable({ payload }) {
  try {
    const { id } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.user.enable, authToken, id)
    yield put(enableUserSuccess(response))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(enableUserFailure())
  }
}

function* userFetchFormSaga({ payload }) {
  try {
    const { formName, userId } = payload
    const authToken = yield select(getToken)
    let response
    if (userId) {
      response = yield call(API.user.fetchFormEdit, authToken, userId)
    } else {
      response = yield call(API.user.fetchFormAdd, authToken)
    }
    yield put(resetFormError(formName))
    yield put(userFetchFormSuccess(response))
  } catch (error) {
    yield put(userFetchFormError())
    yield put(handleErrorApi(error))
  }
}

function* userListSaga({ payload }) {
  try {
    const { parameters } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.user.listRequest, authToken, parameters)
    yield put(userListSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* userPostFormSaga({ payload }) {
  const { formName, userId, values } = payload
  try {
    const authToken = yield select(getToken)
    let response

    if (userId) {
      response = yield call(API.user.postFormEdit, authToken, userId, values)
    } else {
      response = yield call(API.user.postFormAdd, authToken, values)
    }
    yield put(userPostFormSuccess(response))
    yield put(resetFormError(formName))
    yield put(showMessage('form.success'))
    yield put(push('/app/user/list'))
  } catch (error) {
    yield put(handleErrorApi(error, formName))
    yield put(userPostFormError())
  } finally {
    const { setSubmitting } = payload
    setSubmitting(false)
  }
}

function* userUnvalidateSaga({ payload }) {
  try {
    const { userId } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.user.unvalidate, authToken, userId)
    yield put(userUnvalidateSuccess(response))
    yield put(showInfoMessage('user.unvalidateSuccess'))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* userValidateSaga({ payload }) {
  try {
    const { userId } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.user.validate, authToken, userId)
    yield put(userValidateSuccess(response))
    yield put(showInfoMessage('user.validateSuccess'))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(USER_DELETE_START, userDeleteSaga),
    takeEvery(USER_DISABLE_START, disable),
    takeEvery(USER_ENABLE_START, enable),
    takeEvery(USER_FETCH_FORM, userFetchFormSaga),
    takeEvery(USER_LIST_START, userListSaga),
    takeEvery(USER_POST_FORM, userPostFormSaga),
    takeEvery(USER_UNVALIDATE, userUnvalidateSaga),
    takeEvery(USER_VALIDATE, userValidateSaga),
  ])
}
