import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { format } from 'date-fns'

import {
  campaignAssignmentListSuccess,
  campaignAssignmentActionListSuccess,
  campaignCloseSuccess,
  campaignDeleteSuccess,
  campaignDeleteError,
  campaignFetchDataStart,
  campaignFetchDataSuccess,
  campaignFetchFormSuccess,
  campaignFetchFormError,
  campaignFetchStatsSuccess,
  campaignFetchStatsError,
  campaignListSuccess,
  campaignPopinHideStart,
  campaignPostFormSuccess,
  campaignPostFormError,
  campaignVisitExportSuccess,
  campaignVisitExportFailure,
  campaignVisitListSuccess,
  campaignVisitListFailure,
  campaignVisitUpdateSuccess,
  campaignVisitUpdateFailure,
} from 'redux/actions/Campaign'
import { handleErrorApi } from 'redux/actions/Error'
import { resetFormError } from 'redux/actions/Form'
import { showMessage, showInfoMessage } from 'redux/actions/Message'
import {
  CAMPAIGN_ASSIGN_START,
  CAMPAIGN_ASSIGNMENT_LIST,
  CAMPAIGN_ASSIGNMENT_ACTION_LIST,
  CAMPAIGN_CLOSE,
  CAMPAIGN_COLLECT_START,
  CAMPAIGN_DELETE_START,
  CAMPAIGN_FETCH_DATA,
  CAMPAIGN_FETCH_FORM,
  CAMPAIGN_FETCH_STATS_START,
  CAMPAIGN_LIST,
  CAMPAIGN_LOST_START,
  CAMPAIGN_POST_FORM,
  CAMPAIGN_VISIT_EXPORT_START,
  CAMPAIGN_VISIT_LIST_START,
  CAMPAIGN_VISIT_UPDATE_START,
} from 'redux/ActionTypes'

import { getToken } from './Auth'
import API from '../../API'

function* campaignAssignSaga({ payload }) {
  try {
    const { campaignId, userId, data } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.campaign.addAssign, authToken, campaignId, userId, data)
    if (response) {
      yield put(campaignFetchDataStart(campaignId))
      yield put(campaignPopinHideStart())
    }
  } catch (error) {
    yield put(handleErrorApi(error))
  } finally {
    const { setSubmitting } = payload
    setSubmitting(false)
  }
}

function* campaignAssignmentListSaga({ payload }) {
  try {
    const { campaignId, parameters } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.campaign.listAssignmentRequest, authToken, campaignId, parameters)
    yield put(campaignAssignmentListSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* campaignVisitExportSaga({ payload }) {
  try {
    const { campaignId, campaignName } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.campaign.exportVisits, authToken, campaignId)
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: 'application/vnd.ms-excel;charset=utf-8',
      }),
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Export ${campaignName} le ${format(new Date(), 'dd-MM-yyyy')}.xls`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    yield put(campaignVisitExportSuccess())
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(campaignVisitExportFailure())
  }
}

function* campaignVisitListSaga({ payload }) {
  try {
    const { campaignId, parameters } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.campaign.listVisitRequest, authToken, campaignId, parameters)
    yield put(campaignVisitListSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(campaignVisitListFailure())
  }
}

function* campaignVisitUpdateSaga({ payload }) {
  try {
    const { id, delivered } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.visit.update, authToken, id, { delivered })
    yield put(campaignVisitUpdateSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(campaignVisitUpdateFailure())
  }
}

function* campaignAssignmentActionListSaga({ payload }) {
  try {
    const { campaignId, parameters } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.campaign.listAssignmentActionRequest, authToken, campaignId, parameters)
    yield put(campaignAssignmentActionListSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* campaignCloseSaga({ payload }) {
  try {
    const { campaignId } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.campaign.close, authToken, campaignId)
    yield put(campaignCloseSuccess(response))
    yield put(showInfoMessage('campaign.closeSuccess'))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* campaignCollectSaga({ payload }) {
  try {
    const { campaignId, userId, data } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.campaign.addCollect, authToken, campaignId, userId, data)
    if (response) {
      yield put(campaignFetchDataStart(campaignId))
      yield put(campaignPopinHideStart())
    }
  } catch (error) {
    yield put(handleErrorApi(error))
  } finally {
    const { setSubmitting } = payload
    setSubmitting(false)
  }
}

function* campaignDeleteSaga({ payload }) {
  const { id } = payload
  try {
    const authToken = yield select(getToken)
    yield call(API.campaign.deleteCampaign, authToken, id)
    yield put(campaignDeleteSuccess(id))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(campaignDeleteError(id))
  }
}

function* campaignFetchDataSaga({ payload }) {
  try {
    const { campaignId } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.campaign.fetchFormData, authToken, campaignId)
    const isClosable = yield call(API.campaign.isClosable, authToken, campaignId)
    yield put(campaignFetchDataSuccess(response, isClosable))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* campaignFetchFormSaga({ payload }) {
  try {
    const { formName, campaignId } = payload
    const authToken = yield select(getToken)
    let response
    if (campaignId) {
      response = yield call(API.campaign.fetchFormEdit, authToken, campaignId)
    } else {
      response = yield call(API.campaign.fetchFormAdd, authToken)
    }
    yield put(resetFormError(formName))
    yield put(campaignFetchFormSuccess(response))
  } catch (error) {
    yield put(campaignFetchFormError())
    yield put(handleErrorApi(error))
  }
}

function* campaignFetchStatsSaga({ payload }) {
  try {
    const { campaignId } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.campaign.fetchStats, authToken, campaignId)
    yield put(campaignFetchStatsSuccess(response))
  } catch (error) {
    yield put(campaignFetchStatsError())
    yield put(handleErrorApi(error))
  }
}

function* campaignListSaga({ payload }) {
  try {
    const { parameters } = payload
    const authToken = yield select(getToken)
    const data = yield call(API.campaign.listRequest, authToken, parameters)
    yield put(campaignListSuccess(data))
  } catch (error) {
    yield put(handleErrorApi(error))
  }
}

function* campaignLostSaga({ payload }) {
  try {
    const { campaignId, userId, data } = payload
    const authToken = yield select(getToken)
    const response = yield call(API.campaign.addLoss, authToken, campaignId, userId, data)
    if (response) {
      yield put(campaignFetchDataStart(campaignId))
      yield put(campaignPopinHideStart())
    }
  } catch (error) {
    yield put(handleErrorApi(error))
  } finally {
    const { setSubmitting } = payload
    setSubmitting(false)
  }
}

function* campaignPostFormSaga({ payload }) {
  const { formName, id, values } = payload
  try {
    const authToken = yield select(getToken)
    let response

    if (id) {
      response = yield call(API.campaign.postFormEdit, authToken, id, values)
    } else {
      response = yield call(API.campaign.postFormAdd, authToken, values)
    }
    yield put(resetFormError(formName))
    yield put(campaignPostFormSuccess(response))
    yield put(showMessage('form.success'))
    yield put(push('/app/campaign/list'))
  } catch (error) {
    yield put(handleErrorApi(error, formName))
    yield put(campaignPostFormError())
  } finally {
    const { setSubmitting } = payload
    setSubmitting(false)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(CAMPAIGN_ASSIGN_START, campaignAssignSaga),
    takeEvery(CAMPAIGN_ASSIGNMENT_LIST, campaignAssignmentListSaga),
    takeEvery(CAMPAIGN_VISIT_EXPORT_START, campaignVisitExportSaga),
    takeEvery(CAMPAIGN_VISIT_LIST_START, campaignVisitListSaga),
    takeEvery(CAMPAIGN_VISIT_UPDATE_START, campaignVisitUpdateSaga),
    yield takeEvery(CAMPAIGN_ASSIGNMENT_ACTION_LIST, campaignAssignmentActionListSaga),
    yield takeEvery(CAMPAIGN_CLOSE, campaignCloseSaga),
    yield takeEvery(CAMPAIGN_COLLECT_START, campaignCollectSaga),
    yield takeEvery(CAMPAIGN_DELETE_START, campaignDeleteSaga),
    yield takeEvery(CAMPAIGN_FETCH_DATA, campaignFetchDataSaga),
    yield takeEvery(CAMPAIGN_FETCH_FORM, campaignFetchFormSaga),
    yield takeEvery(CAMPAIGN_FETCH_STATS_START, campaignFetchStatsSaga),
    yield takeEvery(CAMPAIGN_LIST, campaignListSaga),
    yield takeEvery(CAMPAIGN_LOST_START, campaignLostSaga),
    yield takeEvery(CAMPAIGN_POST_FORM, campaignPostFormSaga),
  ])
}
