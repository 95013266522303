import { useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

import IntlMessages from 'util/IntlMessages'

import Item from './Item'

const ItemWrapper = ({ item, selected, setSelected }) => {
  const location = useLocation()

  const { id, link, icon, items } = item

  const isSelected = selected === id

  const subLinks = items ? items.map((subItem) => subItem.link) : []
  const isOpen = isSelected || subLinks.includes(location?.pathname)

  return link ? (
    <Item item={item} selected={selected} setSelected={setSelected} />
  ) : (
    <li className={`${items ? 'menu' : ''} ${isOpen ? 'open' : ''}`}>
      <Button
        component="a"
        onClick={() => {
          if (isSelected) {
            setSelected('')
          } else {
            setSelected(id)
          }
        }}
      >
        <i className={`zmdi zmdi-hc-fw ${icon}`} />
        <span className="nav-text">
          <IntlMessages id={id} />
        </span>
      </Button>
      <ul>
        {items.map((subItem) => (
          <Item key={subItem.id} item={subItem} selected={selected} setSelected={setSelected} />
        ))}
      </ul>
    </li>
  )
}

ItemWrapper.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        link: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
  }).isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
}

export default ItemWrapper
