import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { handleErrorApi } from 'redux/actions/Error'
import { showErrorMessage } from 'redux/actions/Message'

import {
  profileAcceptTermOfUseSuccess,
  profileAcceptTermOfUseFailure,
  profileChangePasswordSuccess,
  profileChangePasswordFailure,
  profileForgotPasswordSuccess,
  profileForgotPasswordFailture,
  profileRefreshSuccess,
  profileRefreshFailure,
  profileResetPasswordSuccess,
  profileResetPasswordError,
} from 'redux/actions/Profile'
import {
  PROFILE_ACCEPT_TOU_START,
  PROFILE_CHANGE_PASSWORD_START,
  PROFILE_FORGOT_PASSWORD_START,
  PROFILE_REFRESH_START,
  PROFILE_RESET_PASSWORD,
} from 'redux/ActionTypes'

import API from '../../API'
import { getToken } from './Auth'

export const storeUser = async (authUser) => {
  if (authUser) {
    await localStorage.setItem('auth_user', JSON.stringify(authUser))
  }
}

function* profileAcceptTermOfUseSaga() {
  try {
    const authToken = yield select(getToken)
    const authUser = yield call(API.profile.acceptTermOfUse, authToken)
    yield call(storeUser, authUser)
    yield put(profileAcceptTermOfUseSuccess(authUser))
    yield put(push('/'))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(profileAcceptTermOfUseFailure())
  }
}

function* profileChangePasswordSaga({ payload }) {
  try {
    const { password } = payload
    const authToken = yield select(getToken)
    const authUser = yield call(API.profile.changePassword, authToken, password)
    yield call(storeUser, authUser)
    yield put(profileChangePasswordSuccess(authUser))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(profileChangePasswordFailure())
  }
}

function* profileForgotPasswordSaga({ payload }) {
  try {
    const { trigram, staffNumber } = payload
    const username = `${trigram.trim()}${staffNumber.trim()}`
    const responseJson = yield call(API.profile.forgotPassword, username)
    if (responseJson) {
      yield put(profileForgotPasswordSuccess())
    }
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(profileForgotPasswordFailture())
  }
}

function* profileRefreshSaga() {
  try {
    const authToken = yield select(getToken)
    const authUser = yield call(API.profile.refresh, authToken)
    yield call(storeUser, authUser)
    yield put(profileRefreshSuccess(authUser))
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(profileRefreshFailure())
  }
}

function* profileResetPasswordSaga({ payload }) {
  try {
    const { password, token } = payload
    if (password.match(/\s/g)) {
      yield put(showErrorMessage('auth.resetPassworError.noSpace'))
      yield put(profileResetPasswordError())
      return
    }
    if (password.length < 4) {
      yield put(showErrorMessage('auth.resetPassworError.minError'))
      yield put(profileResetPasswordError())
      return
    }
    if (password.length > 12) {
      yield put(showErrorMessage('auth.resetPassworError.maxError'))
      yield put(profileResetPasswordError())
      return
    }
    const responseJson = yield call(API.profile.resetPassword, password, token)
    if (responseJson) {
      yield put(profileResetPasswordSuccess())
    }
  } catch (error) {
    yield put(profileResetPasswordError())
    if (error.status && error.status === 404) {
      yield put(showErrorMessage('auth.resetTextErrorToken'))
    } else {
      yield put(handleErrorApi(error))
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(PROFILE_ACCEPT_TOU_START, profileAcceptTermOfUseSaga),
    takeEvery(PROFILE_CHANGE_PASSWORD_START, profileChangePasswordSaga),
    takeEvery(PROFILE_FORGOT_PASSWORD_START, profileForgotPasswordSaga),
    takeEvery(PROFILE_REFRESH_START, profileRefreshSaga),
    takeEvery(PROFILE_RESET_PASSWORD, profileResetPasswordSaga),
  ])
}
