import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const authenticate = async (username, password) => {
  const response = await fetch(`${domainName}/api/authenticate`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return json
  }
  throw response
}

export default {
  authenticate,
}
