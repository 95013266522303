import {
  CAMPAIGN_ACTION_SHOW,
  CAMPAIGN_ASSIGN_START,
  CAMPAIGN_ASSIGN_SHOW,
  CAMPAIGN_ASSIGNMENT_LIST,
  CAMPAIGN_ASSIGNMENT_LIST_SUCCESS,
  CAMPAIGN_ASSIGNMENT_ACTION_LIST,
  CAMPAIGN_ASSIGNMENT_ACTION_LIST_SUCCESS,
  CAMPAIGN_COLLECT_START,
  CAMPAIGN_COLLECT_SHOW,
  CAMPAIGN_DELETE_START,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_FETCH_FORM,
  CAMPAIGN_FETCH_FORM_SUCCESS,
  CAMPAIGN_FETCH_FORM_ERROR,
  CAMPAIGN_LOST_SHOW,
  CAMPAIGN_LOST_START,
  CAMPAIGN_POPIN_HIDE,
  CAMPAIGN_POST_FORM,
  CAMPAIGN_POST_FORM_SUCCESS,
  CAMPAIGN_POST_FORM_ERROR,
  CAMPAIGN_LIST,
  CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_CLOSE,
  CAMPAIGN_CLOSE_SUCCESS,
  CAMPAIGN_FETCH_DATA,
  CAMPAIGN_FETCH_DATA_SUCCESS,
  CAMPAIGN_FETCH_STATS_START,
  CAMPAIGN_FETCH_STATS_SUCCESS,
  CAMPAIGN_FETCH_STATS_ERROR,
  CAMPAIGN_VISIT_EXPORT_START,
  CAMPAIGN_VISIT_EXPORT_SUCCESS,
  CAMPAIGN_VISIT_EXPORT_FAILURE,
  CAMPAIGN_VISIT_LIST_START,
  CAMPAIGN_VISIT_LIST_SUCCESS,
  CAMPAIGN_VISIT_LIST_FAILURE,
  CAMPAIGN_VISIT_UPDATE_START,
  CAMPAIGN_VISIT_UPDATE_SUCCESS,
  CAMPAIGN_VISIT_UPDATE_FAILURE,
} from 'redux/ActionTypes'

export const campaignAssignStart = (data, userId, campaignId, setSubmitting) => ({
  type: CAMPAIGN_ASSIGN_START,
  payload: { campaignId, userId, data, setSubmitting },
})

export const campaignAssignShowStart = (userId) => ({
  type: CAMPAIGN_ASSIGN_SHOW,
  payload: { userId },
})

export const campaignAssignmentListStart = (campaignId, parameters) => ({
  type: CAMPAIGN_ASSIGNMENT_LIST,
  payload: { campaignId, parameters },
})

export const campaignAssignmentListSuccess = (data) => ({
  type: CAMPAIGN_ASSIGNMENT_LIST_SUCCESS,
  payload: data,
})

export const campaignAssignmentActionShowStart = (userId) => ({
  type: CAMPAIGN_ACTION_SHOW,
  payload: { userId },
})

export const campaignAssignmentActionListStart = (campaignId, parameters) => ({
  type: CAMPAIGN_ASSIGNMENT_ACTION_LIST,
  payload: { campaignId, parameters },
})

export const campaignAssignmentActionListSuccess = (data) => ({
  type: CAMPAIGN_ASSIGNMENT_ACTION_LIST_SUCCESS,
  payload: data,
})

export const campaignCollectStart = (data, userId, campaignId, setSubmitting) => ({
  type: CAMPAIGN_COLLECT_START,
  payload: { campaignId, userId, data, setSubmitting },
})

export const campaignCollectShowStart = (userId) => ({
  type: CAMPAIGN_COLLECT_SHOW,
  payload: { userId },
})

export const campaignCloseStart = (campaignId) => ({
  type: CAMPAIGN_CLOSE,
  payload: { campaignId },
})

export const campaignCloseSuccess = (campaignData) => ({
  type: CAMPAIGN_CLOSE_SUCCESS,
  payload: { campaignData },
})

export const campaignDeleteStart = (id) => ({
  type: CAMPAIGN_DELETE_START,
  payload: { id },
})

export const campaignDeleteSuccess = (id) => ({
  type: CAMPAIGN_DELETE_SUCCESS,
  payload: { id },
})

export const campaignDeleteError = (id) => ({
  type: CAMPAIGN_DELETE_ERROR,
  payload: { id },
})

export const campaignFetchDataStart = (campaignId) => ({
  type: CAMPAIGN_FETCH_DATA,
  payload: { campaignId },
})

export const campaignFetchDataSuccess = (campaignData, isClosable) => ({
  type: CAMPAIGN_FETCH_DATA_SUCCESS,
  payload: { campaignData, isClosable },
})

export const campaignFetchFormStart = (formName, campaignId) => ({
  type: CAMPAIGN_FETCH_FORM,
  payload: { formName, campaignId },
})

export const campaignFetchFormSuccess = (formDefinition) => ({
  type: CAMPAIGN_FETCH_FORM_SUCCESS,
  payload: { formDefinition },
})

export const campaignFetchFormError = () => ({
  type: CAMPAIGN_FETCH_FORM_ERROR,
})

export const campaignFetchStatsStart = (campaignId) => ({
  type: CAMPAIGN_FETCH_STATS_START,
  payload: { campaignId },
})

export const campaignFetchStatsSuccess = (campaignStats) => ({
  type: CAMPAIGN_FETCH_STATS_SUCCESS,
  payload: { campaignStats },
})

export const campaignFetchStatsError = () => ({
  type: CAMPAIGN_FETCH_STATS_ERROR,
})

export const campaignLostStart = (data, userId, campaignId, setSubmitting) => ({
  type: CAMPAIGN_LOST_START,
  payload: { campaignId, userId, data, setSubmitting },
})

export const campaignLostShowStart = (userId) => ({
  type: CAMPAIGN_LOST_SHOW,
  payload: { userId },
})

export const campaignPopinHideStart = () => ({
  type: CAMPAIGN_POPIN_HIDE,
})

export const campaignPostFormStart = (formName, id, values, setSubmitting) => ({
  type: CAMPAIGN_POST_FORM,
  payload: { formName, id, values, setSubmitting },
})

export const campaignPostFormSuccess = (form) => ({
  type: CAMPAIGN_POST_FORM_SUCCESS,
  payload: form,
})

export const campaignPostFormError = () => ({
  type: CAMPAIGN_POST_FORM_ERROR,
})

export const campaignListStart = (parameters) => ({
  type: CAMPAIGN_LIST,
  payload: { parameters },
})

export const campaignListSuccess = (data) => ({
  type: CAMPAIGN_LIST_SUCCESS,
  payload: data,
})

export const campaignVisitExportStart = (payload) => ({
  type: CAMPAIGN_VISIT_EXPORT_START,
  payload,
})

export const campaignVisitExportSuccess = () => ({
  type: CAMPAIGN_VISIT_EXPORT_SUCCESS,
})

export const campaignVisitExportFailure = () => ({
  type: CAMPAIGN_VISIT_EXPORT_FAILURE,
})

export const campaignVisitListStart = (campaignId, parameters) => ({
  type: CAMPAIGN_VISIT_LIST_START,
  payload: { campaignId, parameters },
})

export const campaignVisitListSuccess = (data) => ({
  type: CAMPAIGN_VISIT_LIST_SUCCESS,
  payload: data,
})

export const campaignVisitListFailure = () => ({
  type: CAMPAIGN_VISIT_LIST_FAILURE,
})

export const campaignVisitUpdateStart = (payload) => ({
  type: CAMPAIGN_VISIT_UPDATE_START,
  payload,
})

export const campaignVisitUpdateSuccess = (data) => ({
  type: CAMPAIGN_VISIT_UPDATE_SUCCESS,
  payload: data,
})

export const campaignVisitUpdateFailure = () => ({
  type: CAMPAIGN_VISIT_UPDATE_FAILURE,
})
