import {
  CAMPAIGN_ACTION_SHOW,
  CAMPAIGN_ASSIGN_SHOW,
  CAMPAIGN_ASSIGNMENT_LIST,
  CAMPAIGN_ASSIGNMENT_LIST_SUCCESS,
  CAMPAIGN_ASSIGNMENT_ACTION_LIST,
  CAMPAIGN_ASSIGNMENT_ACTION_LIST_SUCCESS,
  CAMPAIGN_CLOSE,
  CAMPAIGN_CLOSE_SUCCESS,
  CAMPAIGN_COLLECT_SHOW,
  CAMPAIGN_DELETE_START,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_FETCH_DATA,
  CAMPAIGN_FETCH_DATA_SUCCESS,
  CAMPAIGN_FETCH_FORM,
  CAMPAIGN_FETCH_FORM_SUCCESS,
  CAMPAIGN_FETCH_FORM_ERROR,
  CAMPAIGN_FETCH_STATS_START,
  CAMPAIGN_FETCH_STATS_SUCCESS,
  CAMPAIGN_FETCH_STATS_ERROR,
  CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_LOST_SHOW,
  CAMPAIGN_POPIN_HIDE,
  CAMPAIGN_VISIT_EXPORT_START,
  CAMPAIGN_VISIT_EXPORT_SUCCESS,
  CAMPAIGN_VISIT_EXPORT_FAILURE,
  CAMPAIGN_VISIT_LIST_START,
  CAMPAIGN_VISIT_LIST_SUCCESS,
  CAMPAIGN_VISIT_LIST_FAILURE,
  CAMPAIGN_VISIT_UPDATE_SUCCESS,
} from 'redux/ActionTypes'

const INIT_STATE = {
  initialValues: null,
  rawDataTablesResponse: {
    data: [],
    draw: 0,
    error: '',
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  assignmentRawDataTablesResponse: {
    data: [],
    draw: 0,
    error: '',
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  assignmentActionRawDataTablesResponse: {
    data: [],
    draw: 0,
    error: '',
    recordsFiltered: 0,
    recordsTotal: 0,
  },
  schema: null,
  campaignData: null,
  campaignStats: null,
  loader: false,
  loaderStats: false,
  showAction: false,
  showAssign: false,
  showCollect: false,
  showLost: false,
  selectedUserId: null,
  sentParameters: null,
  sentParametersActions: null,
  pendingCampaignDeletion: [],
  isClosable: false,
  visitLoader: false,
  visitExportingLoader: false,
  visitRawDataTablesResponse: {
    data: [],
    draw: 0,
    error: '',
    recordsFiltered: 0,
    recordsTotal: 0,
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN_ACTION_SHOW: {
      return {
        ...state,
        showAction: true,
      }
    }
    case CAMPAIGN_ASSIGN_SHOW: {
      return {
        ...state,
        showAssign: true,
        selectedUserId: action.payload.userId,
      }
    }
    case CAMPAIGN_ASSIGNMENT_LIST: {
      return {
        ...state,
        sentParameters: action.payload.parameters,
      }
    }
    case CAMPAIGN_ASSIGNMENT_LIST_SUCCESS: {
      return {
        ...state,
        assignmentRawDataTablesResponse: action.payload,
      }
    }
    case CAMPAIGN_ASSIGNMENT_ACTION_LIST: {
      return {
        ...state,
        sentParametersActions: action.payload.parameters,
      }
    }
    case CAMPAIGN_ASSIGNMENT_ACTION_LIST_SUCCESS: {
      return {
        ...state,
        assignmentActionRawDataTablesResponse: action.payload,
      }
    }
    case CAMPAIGN_CLOSE: {
      return {
        ...state,
        loader: true,
      }
    }
    case CAMPAIGN_CLOSE_SUCCESS: {
      return {
        ...state,
        loader: false,
        campaignData: action.payload.campaignData,
      }
    }
    case CAMPAIGN_COLLECT_SHOW: {
      return {
        ...state,
        showCollect: true,
        selectedUserId: action.payload.userId,
      }
    }
    case CAMPAIGN_DELETE_START: {
      return {
        ...state,
        pendingCampaignDeletion: [...state.pendingCampaignDeletion, { id: action.payload.id, status: 'PENDING' }],
      }
    }
    case CAMPAIGN_DELETE_SUCCESS: {
      return {
        ...state,
        pendingCampaignDeletion: state.pendingCampaignDeletion.map((p) =>
          p.id === action.payload.id ? { ...p, status: 'DELETED' } : p,
        ),
        rawDataTablesResponse: {
          ...state.rawDataTablesResponse,
          data: state.rawDataTablesResponse.data.filter((d) => d['c.id'] !== action.payload.id),
        },
      }
    }
    case CAMPAIGN_DELETE_ERROR: {
      return {
        ...state,
        pendingCampaignDeletion: state.pendingCampaignDeletion.filter((p) => p.id !== action.payload.id),
      }
    }
    case CAMPAIGN_FETCH_DATA: {
      return {
        ...state,
        loader: true,
        campaignData: null,
      }
    }
    case CAMPAIGN_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        campaignData: action.payload.campaignData,
        isClosable: action.payload.isClosable,
      }
    }
    case CAMPAIGN_FETCH_FORM: {
      return {
        ...state,
        loader: true,
        campaignData: null,
        initialValues: null,
        schema: null,
      }
    }
    case CAMPAIGN_FETCH_FORM_SUCCESS: {
      return {
        ...state,
        loader: false,
        campaignData: action.payload.formDefinition.campaignData,
        initialValues: action.payload.formDefinition.initialValues,
        schema: action.payload.formDefinition.schema,
      }
    }
    case CAMPAIGN_FETCH_FORM_ERROR: {
      return {
        ...state,
        loader: true,
        campaignData: null,
        initialValues: null,
        schema: null,
      }
    }
    case CAMPAIGN_FETCH_STATS_START: {
      return {
        ...state,
        loaderStats: true,
        campaignStats: null,
      }
    }
    case CAMPAIGN_FETCH_STATS_SUCCESS: {
      return {
        ...state,
        loaderStats: false,
        campaignStats: action.payload.campaignStats,
      }
    }
    case CAMPAIGN_FETCH_STATS_ERROR: {
      return {
        ...state,
        loaderStats: false,
        campaignStats: null,
      }
    }
    case CAMPAIGN_LIST_SUCCESS: {
      return {
        ...state,
        rawDataTablesResponse: action.payload,
      }
    }
    case CAMPAIGN_LOST_SHOW: {
      return {
        ...state,
        showLost: true,
        selectedUserId: action.payload.userId,
      }
    }
    case CAMPAIGN_POPIN_HIDE: {
      return {
        ...state,
        showAction: false,
        showAssign: false,
        showCollect: false,
        showLost: false,
        selectedUserId: null,
      }
    }
    case CAMPAIGN_VISIT_EXPORT_START: {
      return {
        ...state,
        visitExportingLoader: true,
      }
    }
    case CAMPAIGN_VISIT_EXPORT_SUCCESS: {
      return {
        ...state,
        visitExportingLoader: false,
      }
    }
    case CAMPAIGN_VISIT_EXPORT_FAILURE: {
      return {
        ...state,
        visitExportingLoader: false,
      }
    }
    case CAMPAIGN_VISIT_LIST_START: {
      return {
        ...state,
        visitLoader: true,
        visitSentParameters: action.payload.parameters,
      }
    }
    case CAMPAIGN_VISIT_LIST_SUCCESS: {
      return {
        ...state,
        visitLoader: false,
        visitRawDataTablesResponse: action.payload,
      }
    }
    case CAMPAIGN_VISIT_LIST_FAILURE: {
      return {
        ...state,
        visitLoader: false,
      }
    }
    case CAMPAIGN_VISIT_UPDATE_SUCCESS: {
      return {
        ...state,
        visitRawDataTablesResponse: {
          ...state.visitRawDataTablesResponse,
          data: [
            ...state.visitRawDataTablesResponse.data.map((v) => {
              if (v['v.id'] === action.payload.id) {
                v['v.delivered'] = action.payload.delivered // eslint-disable-line no-param-reassign
              }
              return v
            }),
          ],
        },
      }
    }
    default:
      return state
  }
}
