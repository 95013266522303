import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { format } from 'date-fns'

import {
  calculateIncomeSuccess,
  calculateIncomeFailure,
  exportXlsIncomeSuccess,
  exportXlsIncomeFailure,
} from 'redux/actions/Accounting'
import { handleErrorApi } from 'redux/actions/Error'
import { ACCOUNTING_CALCULATE_START, ACCOUNTING_EXPORT_XLS_START } from 'redux/ActionTypes'
import { captureException } from 'util/Sentry'

import { getToken } from './Auth'
import API from '../../API'

function* calculate({ payload }) {
  try {
    const authToken = yield select(getToken)
    const { campaigns } = payload
    const campaignsId = campaigns.map((c) => c.id)
    const response = yield call(API.accounting.calculate, authToken, campaignsId)
    yield put(calculateIncomeSuccess(response))
  } catch (error) {
    captureException(error)
    yield put(handleErrorApi(error))
    yield put(calculateIncomeFailure())
  }
}

function* exportXls({ payload }) {
  try {
    const authToken = yield select(getToken)
    const { campaigns } = payload
    const campaignsId = campaigns.map((c) => c.id)
    const data = yield call(API.accounting.exportXls, authToken, campaignsId)
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: 'application/vnd.ms-excel;charset=utf-8',
      }),
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Export des revenus le ${format(new Date(), 'dd-MM-yyyy')}.xls`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    yield put(exportXlsIncomeSuccess())
  } catch (error) {
    yield put(handleErrorApi(error))
    yield put(exportXlsIncomeFailure())
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACCOUNTING_CALCULATE_START, calculate)])
  yield all([takeEvery(ACCOUNTING_EXPORT_XLS_START, exportXls)])
}
