import { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import { profileForgotPasswordStart } from 'redux/actions/Profile'
import logoWhite from 'assets/images/dmasp-logo-texte-blanc.png'
import IntlMessages from 'util/IntlMessages'

const ForgotPassword = ({ errorForgotPassword, loader, match, profileForgotPassword }) => {
  const [displayForm, setDisplayForm] = useState(true)
  const [trigram, setTrigram] = useState(match.params.trigram)
  const [staffNumber, setStaffNumber] = useState(match.params.staffNumber)

  return (
    /* eslint-disable-next-line max-len */
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="DMASP">
            <img src={logoWhite} alt="DMASP" title="DMASP" />
          </Link>
        </div>
        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="auth.forgotPassword" />
            </h1>
          </div>
          {displayForm && (
            <div className="app-login-form">
              <p className="mb-0">
                <IntlMessages id="auth.forgotText" />
              </p>

              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="auth.trigram" />}
                    fullWidth
                    onChange={(event) => setTrigram(event.target.value.toUpperCase())}
                    value={trigram}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    label={<IntlMessages id="auth.staffNumber" />}
                    fullWidth
                    onChange={(event) => setStaffNumber(event.target.value.toUpperCase())}
                    value={staffNumber}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        setDisplayForm(false)
                        profileForgotPassword({
                          trigram,
                          staffNumber,
                        })
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="auth.send" />
                    </Button>
                    <Link to="/" title={<IntlMessages id="auth.cancel" />}>
                      <IntlMessages id="auth.cancel" />
                    </Link>
                  </div>
                </fieldset>
              </form>
            </div>
          )}
          {!loader && !displayForm && (
            <div className="app-login-form">
              {errorForgotPassword ? (
                <IntlMessages id="error.errorOccured" />
              ) : (
                <IntlMessages id="auth.forgotTextSuccess" />
              )}
              <div className="mt-3 d-flex align-items-right justify-content-between">
                <Link to="/" title={<IntlMessages id="auth.back" />}>
                  <IntlMessages id="auth.back" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ profile }) => {
  const { errorForgotPassword, loader } = profile
  return {
    errorForgotPassword,
    loader,
  }
}

ForgotPassword.propTypes = {
  errorForgotPassword: PropTypes.bool.isRequired,
  loader: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      trigram: PropTypes.string,
      staffNumber: PropTypes.string,
    }),
  }).isRequired,
  profileForgotPassword: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  profileForgotPassword: profileForgotPasswordStart,
})(ForgotPassword)
