import { domainName } from './config'
import { getApiMessage } from '../util/ApiMessage'

const fetchMapVisits = async (authToken) => {
  const response = await fetch(`${domainName}/api/visits/list`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const json = await getApiMessage(response)
    return {
      visits: json.visits,
    }
  }
  throw response
}

export default {
  fetchMapVisits,
}
