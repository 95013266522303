import {
  CARTOGRAPHY_FETCH_MAP_VISITS_START,
  CARTOGRAPHY_FETCH_MAP_VISITS_SUCCESS,
  CARTOGRAPHY_FETCH_MAP_VISITS_FAILURE,
  CARTOGRAPHY_SET_FILTERS_START,
  CARTOGRAPHY_SET_MAP_CURRENT_POSITION_START,
  CARTOGRAPHY_SET_MAP_CURRENT_POSITION_SUCCESS,
  CARTOGRAPHY_SET_MAP_CURRENT_POSITION_ERROR,
  CARTOGRAPHY_SET_MAP_CURRENT_ZOOM_SUCCESS,
} from 'redux/ActionTypes'

export const setFiltersStart = (filters) => ({
  type: CARTOGRAPHY_SET_FILTERS_START,
  payload: filters,
})

export const fetchMapVisitsStart = () => ({
  type: CARTOGRAPHY_FETCH_MAP_VISITS_START,
})

export const fetchMapVisitsSuccess = (visits) => ({
  type: CARTOGRAPHY_FETCH_MAP_VISITS_SUCCESS,
  payload: { visits },
})

export const fetchMapVisitsFailure = () => ({
  type: CARTOGRAPHY_FETCH_MAP_VISITS_FAILURE,
})

export const setMapCurrentPositionStart = () => ({
  type: CARTOGRAPHY_SET_MAP_CURRENT_POSITION_START,
})

export const setMapCurrentPositionSuccess = (lat, lng) => ({
  type: CARTOGRAPHY_SET_MAP_CURRENT_POSITION_SUCCESS,
  payload: { lat, lng },
})

export const setMapCurrentPositionError = () => ({
  type: CARTOGRAPHY_SET_MAP_CURRENT_POSITION_ERROR,
})

export const setMapCurrentZoomSuccess = (zoom) => ({
  type: CARTOGRAPHY_SET_MAP_CURRENT_ZOOM_SUCCESS,
  payload: zoom,
})
