import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { captureException } from 'util/Sentry'
import { CARTOGRAPHY_FETCH_MAP_VISITS_START } from 'redux/ActionTypes'
import { fetchMapVisitsSuccess, fetchMapVisitsFailure } from 'redux/actions/Cartography'
import { handleErrorApi } from 'redux/actions/Error'

import { getToken } from './Auth'
import API from '../../API'

function* fetchMapVisitsSaga() {
  try {
    const authToken = yield select(getToken)
    const data = yield call(API.cartography.fetchMapVisits, authToken)
    yield put(fetchMapVisitsSuccess(data.visits))
  } catch (error) {
    captureException(error)
    yield put(handleErrorApi(error))
    yield put(fetchMapVisitsFailure())
  }
}

export default function* rootSaga() {
  yield all([takeEvery(CARTOGRAPHY_FETCH_MAP_VISITS_START, fetchMapVisitsSaga)])
}
