import PropTypes, { oneOfType } from 'prop-types'

export const authUserPropTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
})

export const campaignAssignmentData = PropTypes.shape({
  'u.id': PropTypes.string,
  'u.staffNumber': PropTypes.string,
  'u.firstName': PropTypes.string,
  'u.lastName': PropTypes.string,
  'ca.calendarAssigned': PropTypes.number,
  'ca.calendarPossessed': PropTypes.number,
  'ca.calendarSold': PropTypes.number,
  'ca.calendarReturned': PropTypes.number,
  'ca.calendarLost': PropTypes.number,
  'ca.amountCollected': PropTypes.number,
  'ca.amountCollectedBankCheck': PropTypes.number,
  'ca.amountCollectedCard': PropTypes.number,
  'ca.amountCollectedCash': PropTypes.number,
  'ca.amountCollectedEpt': PropTypes.number,
  'ca.amountPossessed': PropTypes.number,
  'ca.amountPossessedCash': PropTypes.number,
  'ca.amountPossessedBankCheck': PropTypes.number,
  'ca.amountReturned': PropTypes.number,
  'ca.amountReturnedCash': PropTypes.number,
  'ca.amountReturnedBankCheck': PropTypes.number,
  'ca.amountLost': PropTypes.number,
  'ca.amountLostBankCheck': PropTypes.number,
  'ca.amountLostCash': PropTypes.number,
})

export const customerPropTypes = PropTypes.shape({
  mapDefaultLatitude: PropTypes.number,
  mapDefaultLongitude: PropTypes.number,
  mapDefaultZoom: PropTypes.number,
  stripeAccount: PropTypes.string,
  stripeStatus: oneOfType([PropTypes.number, PropTypes.string]),
})

export const userDataPropTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  photoUrl: PropTypes.string,
  profileModified: PropTypes.bool,
  validated: PropTypes.bool,
})

export const matchPropTypes = PropTypes.shape({ url: PropTypes.string })
