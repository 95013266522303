import { DRAWER_TYPE, FIXED_DRAWER, SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from 'redux/ActionTypes'
import { DARK_INDIGO } from 'constants/ThemeColors'

const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: DARK_INDIGO,
  width: window.innerWidth,
  locale: {
    languageId: 'french',
    locale: 'fr',
    name: 'French',
    icon: 'fr',
  },
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      }
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed,
      }
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType,
      }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      }
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      }
    default:
      return state
  }
}

export default settings
