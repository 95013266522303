import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import frLocale from 'date-fns/locale/fr'
import { arrayOf, element, oneOfType } from 'prop-types'

const MuiPickersWrapper = ({ children }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
    {children}
  </MuiPickersUtilsProvider>
)

MuiPickersWrapper.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
}

export default MuiPickersWrapper
