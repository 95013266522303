import frMessages from '../locales/fr_FR.json'

const frLang = {
  messages: {
    ...frMessages,
  },
  locale: 'fr-FR',
}

export default frLang
