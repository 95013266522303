import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hasRoles } from './helpers'

const Authorization = ({ allowedRoles, authUser, children }) => {
  const { roles } = authUser

  if (hasRoles(allowedRoles, roles)) {
    return <>{children}</>
  }

  return null
}

Authorization.propTypes = {
  authUser: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
}

const mapStateToProps = ({ profile }) => {
  const { authUser } = profile
  return { authUser }
}

export default connect(mapStateToProps)(Authorization)
